import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logNoteNeedsRefetch: false,
};

const logNote = createSlice({
  name: 'logNote',
  initialState,
  reducers: {
    setlogNoteNeedsRefetch: (state, { payload }) => {
      state.logNoteNeedsRefetch = payload;
    },
    clearLogNoteState: () => {
      return initialState;
    },
  },
});

export const { setlogNoteNeedsRefetch, clearLogNoteState } = logNote.actions;
export default logNote.reducer;
