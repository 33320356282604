import { createSlice } from '@reduxjs/toolkit';

export interface IFallRisk {
  Total_score: { magnitude: number };
  Comment: { value: string };
  Clinical_Interpretation: { value: string };
  Activity_of_daily: { value: string };
  Assistive_device: { value: string };
  Composition_uid: string;
}

type FallRiskAssessmentState = {
  fallRiskAssessment: IFallRisk;
  showForm: boolean;
};

const initialState: FallRiskAssessmentState = {
  fallRiskAssessment: {} as IFallRisk,
  showForm: false,
};

const fallRiskAssessment = createSlice({
  name: 'fallRiskAssessment',
  initialState,
  reducers: {
    updateFallRiskAssessment: (state, { payload }) => {
      state.fallRiskAssessment = payload;
    },
    openFallRiskAssessmentForm: (state) => {
      state.showForm = true;
    },
    closeFallRiskAssessmentForm: (state) => {
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateFallRiskAssessment,
  openFallRiskAssessmentForm,
  closeFallRiskAssessmentForm,
  clearState,
} = fallRiskAssessment.actions;
export default fallRiskAssessment.reducer;
