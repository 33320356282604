import { createSlice } from '@reduxjs/toolkit';

type SettingsSlice = {
  expandSidebar: boolean;
  enableTransitionControlCheckbox: boolean;
  enableDragDropTransition: boolean;
  branches: Record<string, boolean>;
  antenatal: Record<string, boolean>;
  showAddAssessmentModal: boolean;
};

const initialState: SettingsSlice = {
  expandSidebar: false,
  enableTransitionControlCheckbox: false,
  enableDragDropTransition: false,
  branches: {
    'Abuja - Asba & Dantata': true,
    'Abuja - Asokoro Test center': true,
    'Kano Independence Road': true,
    'Lagos - Sangotedo Ajah': true,
    'Abuja - Lugbe': true,
    'Kano - Lamido Crescent': true,
  },
  antenatal: {
    vaccinationsDue: false,
    vaccinationsNotDueNotStarted: false,
  },
  showAddAssessmentModal: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setExpandSidebar: (state, { payload }) => {
      state.expandSidebar = payload;
    },
    toggleExpandSidebar: (state) => {
      state.expandSidebar = !state.expandSidebar;
    },

    handleEnableTransitionControlCheckbox: (
      state,
      { payload: { payload, type } },
    ) => {
      if (type === 'TOGGLE') {
        state.enableTransitionControlCheckbox =
          !state.enableTransitionControlCheckbox;
      } else if (type === 'SET') {
        state.enableTransitionControlCheckbox = payload;
      }
    },

    handleEnableDragDropTransition: (state, { payload: { payload, type } }) => {
      if (type === 'TOGGLE') {
        state.enableDragDropTransition = !state.enableDragDropTransition;
      } else if (type === 'SET') {
        state.enableDragDropTransition = payload;
      }
    },

    handleBranchChange: (state, { payload: { name, payload, type } }) => {
      if (type === 'TOGGLE') {
        state.branches = {
          ...state.branches,
          [name]: !state.branches[name],
        };
      } else if (type === 'SET') {
        state.branches = {
          ...state.branches,
          [name]: payload,
        };
      }
    },
    handleAntenatal: (state, { payload: { name, payload, type } }) => {
      if (type === 'TOGGLE') {
        state.antenatal = {
          ...state.antenatal,
          [name]: !state.antenatal[name],
        };
      } else if (type === 'SET') {
        state.antenatal = {
          ...state.antenatal,
          [name]: payload,
        };
      }
    },

    handleAddAssessmentModal: (state, { payload }) => {
      state.showAddAssessmentModal = payload;
    },
  },
});

export const {
  setExpandSidebar,
  toggleExpandSidebar,
  handleEnableTransitionControlCheckbox,
  handleEnableDragDropTransition,
  handleBranchChange,
  handleAntenatal,
  handleAddAssessmentModal,
} = settingsSlice.actions;
export default settingsSlice.reducer;
