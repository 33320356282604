import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pastDentalHistory: null,
  isAddPastDentalHistory: false,
  isEditPastDentalHistory: false,
  showForm: false,
};

const pastDentalHistory = createSlice({
  name: 'pastDentalHistory',
  initialState,
  reducers: {
    updatePastDentalHistory: (state, { payload }) => {
      state.pastDentalHistory = payload;
    },
    openAddPastDentalHistoryForm: (state) => {
      state.isAddPastDentalHistory = true;
      state.isEditPastDentalHistory = false;
      state.showForm = true;
    },
    openEditPastDentalHistoryForm: (state) => {
      state.isEditPastDentalHistory = true;
      state.isAddPastDentalHistory = false;
      state.showForm = true;
    },
    closePastDentalHistoryForm: (state) => {
      state.isAddPastDentalHistory = false;
      state.isEditPastDentalHistory = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updatePastDentalHistory,
  openAddPastDentalHistoryForm,
  openEditPastDentalHistoryForm,
  closePastDentalHistoryForm,
  clearState,
} = pastDentalHistory.actions;
export default pastDentalHistory.reducer;
