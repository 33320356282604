import { createSlice } from '@reduxjs/toolkit';

export interface FollowUp {
  id?: string;
  subject: string;
  body: string;
  email_to: string;
  message_to: string;
  message_from: string;
  message_type: string;
  encounter_no: string;
  followup_date: string;
  create_username: string;
  booking_id?: string;
  create_date?: string;
  branch_id?: number;
  comment: string;
  resource_id: string;
}
const followUpMessages: FollowUp[] = [];

const initialState = {
  followUpMessages,
  followUpMessageRemovalState: true,
};

const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    allFollowUpMessages: (state, { payload }) => {
      state.followUpMessages = payload;
    },
    removeFollowUpMessage: (state, { payload }) => {
      state.followUpMessages = state.followUpMessages.filter(
        (eachMessage) => eachMessage.id !== payload.id,
      );
    },
    addFollowUpMessage: (state, { payload }) => {
      state.followUpMessages = [...state.followUpMessages, payload];
    },
    updateFollowUpMessage: (state, { payload }) => {
      state.followUpMessages = state.followUpMessages.map((eachMessage) => {
        if (eachMessage.id === payload.id) {
          eachMessage = payload;
        }
        return eachMessage;
      });
    },
    updateRemoveFollowUpMessage: (state, { payload }) => {
      state.followUpMessageRemovalState = payload;
    },
  },
});

export const {
  allFollowUpMessages,
  removeFollowUpMessage,
  addFollowUpMessage,
  updateFollowUpMessage,
  updateRemoveFollowUpMessage,
} = messageSlice.actions;
export default messageSlice.reducer;
