import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  familyHistory: [],
  isAddFamilyHistory: false,
  isEditFamilyHistory: false,
  showForm: false,
};

const familyHistory = createSlice({
  name: 'familyHistory',
  initialState,
  reducers: {
    updateFamilyHistory: (state, { payload }) => {
      state.familyHistory = payload;
    },
    openAddFamilyHistoryForm: (state) => {
      state.isAddFamilyHistory = true;
      state.isEditFamilyHistory = false;
      state.showForm = true;
    },
    openEditFamilyHistoryForm: (state) => {
      state.isEditFamilyHistory = true;
      state.isAddFamilyHistory = false;
      state.showForm = true;
    },
    closeFamilyHistoryForm: (state) => {
      state.isAddFamilyHistory = false;
      state.isEditFamilyHistory = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateFamilyHistory,
  openAddFamilyHistoryForm,
  openEditFamilyHistoryForm,
  closeFamilyHistoryForm,
  clearState,
} = familyHistory.actions;
export default familyHistory.reducer;
