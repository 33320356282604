import { createSlice } from '@reduxjs/toolkit';

type MedicationAdministeredState = {
  medicationAdministeredList: any[];
  isAddMedicationAdministered: boolean;
  isEditMedicationAdministered: boolean;
  showForm: boolean;
};

const initialState: MedicationAdministeredState = {
  medicationAdministeredList: [],
  isAddMedicationAdministered: false,
  isEditMedicationAdministered: false,
  showForm: false,
};

const medicationAdministeredSlice = createSlice({
  name: 'medicationAdministered',
  initialState,
  reducers: {
    updateMedicationAdministered: (state, { payload }) => {
      state.medicationAdministeredList = payload;
    },
    openAddMedicationAdministered: (state) => {
      state.isAddMedicationAdministered = true;
      state.showForm = true;
    },
    openEditMedicationAdministered: (state) => {
      state.isEditMedicationAdministered = true;
      state.showForm = true;
    },
    closeMedicationAdministered: (state) => {
      state.isAddMedicationAdministered = false;
      state.isEditMedicationAdministered = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateMedicationAdministered,
  openAddMedicationAdministered,
  openEditMedicationAdministered,
  closeMedicationAdministered,
  clearState,
} = medicationAdministeredSlice.actions;
export default medicationAdministeredSlice.reducer;
