import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFormRendered: false,
};

const formRenderer = createSlice({
  name: 'formRenderer',
  initialState,
  reducers: {
    setIsFormRendered: (state, { payload }) => {
      state.isFormRendered = payload;
    },
    clearFormRendererState: () => {
      return initialState;
    },
  },
});

export const { setIsFormRendered, clearFormRendererState } =
  formRenderer.actions;
export default formRenderer.reducer;
