import { MedicalReport } from '@interfaces/medicalReport';
import { createSlice } from '@reduxjs/toolkit';

type MedicalReportState = {
  medicalReport: MedicalReport | null;
  showReportModal: boolean;
  showReportPreviewModal: boolean;
};

const initialState: MedicalReportState = {
  medicalReport: null,
  showReportModal: false,
  showReportPreviewModal: false,
};

const medicalReportSlice = createSlice({
  name: 'medicalReport',
  initialState,
  reducers: {
    setMedicalReport: (state, { payload }) => {
      state.medicalReport = payload;
    },
    setShowReportModal: (state, { payload }) => {
      state.showReportModal = payload;
    },
    setShowReportPreviewModal: (state, { payload }) => {
      state.showReportPreviewModal = payload;
    },

    clearState: () => {
      return initialState;
    },
  },
});

export const {
  setMedicalReport,
  clearState,
  setShowReportModal,
  setShowReportPreviewModal,
} = medicalReportSlice.actions;

export default medicalReportSlice.reducer;
