import React, { createContext, useReducer } from 'react';

// actions
const ACTIVATE_MEDICAL = 'ACTIVATE_MEDICAL';
const ACTIVATE_MEDICAL_HISTORY = 'ACTIVATE_MEDICAL_HISTORY';
const ACTIVATE_GYNAECOLOGY_SUMMARY = 'ACTIVATE_GYNAECOLOGY_SUMMARY';
const ACTIVATE_PERSONAL = 'ACTIVATE_PERSONAL';
const ACTIVATE_ENCOUNTERS = 'ACTIVATE_ENCOUNTERS';
const ACTIVATE_LABTEST = 'ACTIVATE_LABTEST';
const ACTIVATE_BLOOD_TRANSFUSION = 'ACTIVATE_BLOOD_TRANSFUSION';
const ACTIVATE_PRESCRIPTION = 'ACTIVATE_PRESCRIPTION';
const ACTIVATE_ADMISSION = 'ACTIVATE_ADMISSION';
const ACTIVATE_DOCUMENT = 'ACTIVATE_DOCUMENT';
const ACTIVATE_SOCIOECONOMICS_SUMMARY = 'ACTIVATE_SOCIOECONOMICS_SUMMARY';
const ACTIVATE_BMGF_LLM_STUDIES = 'ACTIVATE_BMGF_LLM_STUDIES';
const ACTIVATE_VACCINATIONS = 'ACTIVATE_VACCINATIONS';
const ACTIVATE_ANTENATAL_CARE = 'ACTIVATE_ANTENATAL_CARE';

interface IinitialState {
  isMedicalView: boolean;
  isMedicalHistoryView: boolean;
  isGynaecologySummaryView: boolean;
  isSocioeconomicsView: boolean;
  isPersonalView: boolean;
  isEncountersView: boolean;
  isLabtestView: boolean;
  isBloodTransfusionView: boolean;
  isDocumentView: boolean;
  isPrescriptionView: boolean;
  isAdmissionView: boolean;
  isBMGFStudiesView: boolean;
  isVaccinationView: boolean;
  isAntenatalCareView: boolean;
}

interface IDashboardView {
  patientSideNav: IinitialState;
  activateView: (view: string) => void;
}

export const patientSideNavContext = createContext({} as IDashboardView);

const initialState = {
  isMedicalView: true,
  isMedicalHistoryView: false,
  isGynaecologySummaryView: false,
  isSocioeconomicsView: false,
  isPersonalView: false,
  isEncountersView: false,
  isLabtestView: false,
  isBloodTransfusionView: false,
  isPrescriptionView: false,
  isDocumentView: false,
  isAdmissionView: false,
  isBMGFStudiesView: false,
  isVaccinationView: false,
  isAntenatalCareView: false,
};

type actionTypes = {
  type: string;
};

const patientSideNabReducer = (
  previousState: IinitialState,
  action: actionTypes,
) => {
  switch (action.type) {
    case ACTIVATE_MEDICAL:
      return {
        ...initialState,
        isMedicalView: true,
      };
    case ACTIVATE_MEDICAL_HISTORY:
      return {
        ...initialState,
        isMedicalView: false,
        isMedicalHistoryView: true,
      };
    case ACTIVATE_GYNAECOLOGY_SUMMARY:
      return {
        ...initialState,
        isMedicalView: false,
        isGynaecologySummaryView: true,
      };
    case ACTIVATE_SOCIOECONOMICS_SUMMARY:
      return {
        ...initialState,
        isMedicalView: false,
        isSocioeconomicsView: true,
      };
    case ACTIVATE_PERSONAL:
      return {
        ...initialState,
        isMedicalView: false,
        isPersonalView: true,
      };
    case ACTIVATE_ENCOUNTERS:
      return {
        ...initialState,
        isMedicalView: false,
        isEncountersView: true,
      };
    case ACTIVATE_LABTEST:
      return {
        ...initialState,
        isMedicalView: false,
        isLabtestView: true,
      };
    case ACTIVATE_BLOOD_TRANSFUSION:
      return {
        ...initialState,
        isMedicalView: false,
        isBloodTransfusionView: true,
      };
    case ACTIVATE_PRESCRIPTION:
      return {
        ...initialState,
        isMedicalView: false,
        isPrescriptionView: true,
      };
    case ACTIVATE_DOCUMENT:
      return {
        ...initialState,
        isMedicalView: false,
        isDocumentView: true,
      };
    case ACTIVATE_ADMISSION:
      return {
        ...initialState,
        isMedicalView: false,
        isAdmissionView: true,
      };
    case ACTIVATE_BMGF_LLM_STUDIES:
      return {
        ...initialState,
        isMedicalView: false,
        isBMGFStudiesView: true,
      };

    case ACTIVATE_VACCINATIONS:
      return {
        ...initialState,
        isMedicalView: false,
        isVaccinationView: true,
      };

    case ACTIVATE_ANTENATAL_CARE:
      return {
        ...initialState,
        isMedicalView: false,
        isAntenatalCareView: true,
      };
    default:
      return initialState;
  }
};

export const PatientSideNavState: React.FC<React.ReactNode> = ({
  children,
}: any) => {
  const [state, dispatch] = useReducer(patientSideNabReducer, initialState);

  const activateView = (view: string) => {
    return dispatch({
      type: view,
    });
  };

  return (
    <patientSideNavContext.Provider
      value={{ patientSideNav: state, activateView }}>
      {children}
    </patientSideNavContext.Provider>
  );
};
