import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pastOcularHistory: null,
  isAddPastOcularHistory: false,
  isEditPastOcularHistory: false,
  showForm: false,
};

const pastOcularHistory = createSlice({
  name: 'pastOcularHistory',
  initialState,
  reducers: {
    updatePastOcularHistory: (state, { payload }) => {
      state.pastOcularHistory = payload;
    },
    openAddPastOcularHistoryForm: (state) => {
      state.isAddPastOcularHistory = true;
      state.isEditPastOcularHistory = false;
      state.showForm = true;
    },
    openEditPastOcularHistoryForm: (state) => {
      state.isEditPastOcularHistory = true;
      state.isAddPastOcularHistory = false;
      state.showForm = true;
    },
    closePastOcularHistoryForm: (state) => {
      state.isAddPastOcularHistory = false;
      state.isEditPastOcularHistory = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updatePastOcularHistory,
  openAddPastOcularHistoryForm,
  openEditPastOcularHistoryForm,
  closePastOcularHistoryForm,
  clearState,
} = pastOcularHistory.actions;
export default pastOcularHistory.reducer;
