import { createSlice } from '@reduxjs/toolkit';
import { ILabOrder } from '@interfaces/labAdmin';

type LabOrderType = {
  labOrders: ILabOrder[];
  activeLabOrder: ILabOrder | null;
  isAddLabOrder: boolean;
  isEditLabOrder: boolean;
  activeOrderCompositionId: string;
  labCareProvider: string;
  status: string;
  flag: string;
};

const initialState: LabOrderType = {
  labOrders: [],
  activeLabOrder: null,
  activeOrderCompositionId: '',
  isAddLabOrder: false,
  isEditLabOrder: false,
  labCareProvider: '',
  status: '',
  flag: '',
};

const labOrder = createSlice({
  name: 'labOrder',
  initialState,
  reducers: {
    updateLabOrders: (state, { payload }) => {
      state.labOrders = payload;
    },
    setActiveOrder: (state, { payload }) => {
      state.activeLabOrder = payload;
    },
    setActiveOrderCompositionId: (state, { payload }) => {
      state.activeOrderCompositionId = payload;
    },
    openAddLabOrder: (state) => {
      state.isAddLabOrder = true;
    },
    openEditLabOrder: (state) => {
      state.isEditLabOrder = true;
    },
    closeLabOrderForm: (state) => {
      state.isAddLabOrder = false;
      state.isEditLabOrder = false;
    },
    updateStatus: (state, { payload }) => {
      state.status = payload;
    },
    updateFlag: (state, { payload }) => {
      state.flag = payload;
    },
    updateLabCareProvider: (state, { payload }) => {
      state.labCareProvider = payload;
    },
    clearStatusFIlter: (state) => {
      state.status = '';
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  clearState,
  updateLabOrders,
  openAddLabOrder,
  openEditLabOrder,
  closeLabOrderForm,
  setActiveOrder,
  setActiveOrderCompositionId,
  updateLabCareProvider,
  updateStatus,
  updateFlag,
  clearStatusFIlter,
} = labOrder.actions;
export default labOrder.reducer;
