import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  obstetricHistory: null,
  showForm: false,
};

const obstetricHistory = createSlice({
  name: 'obstetricHistory',
  initialState,
  reducers: {
    updateObstetricHistory: (state, { payload }) => {
      state.obstetricHistory = payload;
    },
    openObstetricHistoryForm: (state) => {
      state.showForm = true;
    },
    closeObstetricHistoryForm: (state) => {
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateObstetricHistory,
  openObstetricHistoryForm,
  closeObstetricHistoryForm,
  clearState,
} = obstetricHistory.actions;
export default obstetricHistory.reducer;
