import { createSlice } from '@reduxjs/toolkit';
export type SearchedPatient = {
  name: string;
  id: string;
  dob: string;
  phone: string;
  patient_ref: string;
  email: string;
  gender: string;
};
//export ty
const initialState: {
  searchedPatient: SearchedPatient | null;
  error: boolean;
} = {
  searchedPatient: null,
  error: false,
};

const searchedPatientSlice = createSlice({
  name: 'Searched Patient',
  initialState,
  reducers: {
    updateSearchedPatient: (state, { payload }) => {
      state.searchedPatient = payload;
    },
    removeSearchedPatient: () => {
      return initialState;
    },
    setSearchPatientError: (state, { payload }) => {
      state.error = payload;
    },
  },
});
export const {
  updateSearchedPatient,
  removeSearchedPatient,
  setSearchPatientError,
} = searchedPatientSlice.actions;
export default searchedPatientSlice.reducer;
