import moment from 'moment';

export const getAgeFromDob = (dob: string) => {
  dob = dob?.substring(0, 10);

  const lifetime = moment.duration(moment().diff(dob));
  const y = lifetime.years();
  const m = lifetime.months();

  const years = `${y}y` ?? '';
  const months = `${m}m` ?? '';

  return (
    <>
      {years}
      <span className="months">{months}</span>
    </>
  );
};

export const reformatDob = (dob: string) => {
  if (dob === '' || dob === undefined || dob === null) {
    return '';
  }

  if (dob.length < 10) return '';

  dob = dob.substring(0, 10);

  const yyyy = dob.split('-')[0];
  const mm = dob.split('-')[1];
  const dd = dob.split('-')[2];

  const date = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

  const formatedDob = moment(date).format('MMM D Y');
  return formatedDob;
};

export const reformatDateToMonthAndYear = (date: Date) => {
  if (!date) {
    return '';
  }

  const reformatedDate = moment(date).format('MMM YYYY');
  return reformatedDate;
};

export const combineYearAndMonth = (year: Date | null, month: Date | null) => {
  if (year && month) {
    let yearDate = new Date(year);
    let monthDate = new Date(month);

    let yearValue = yearDate.getFullYear();
    //Note: getMonth returns a number value for months starting from 0, i.e Jan = 0, Feb = 1, Mar = 2
    let monthValue = monthDate.getMonth();
    let combinedDate = new Date(yearValue, monthValue, 1);

    return combinedDate;
  }

  return null;
};

export const getPatientAgeAsInt = (dob: string) => {
  return Number(getAgeFromDob(dob).props.children[0].slice(0, -1));
};
