import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alcoholSummaries: [],
  tobaccoSummaries: [],
  substanceSummaries: [],
  activeAlcoholSummaryCompositionID: '',
  activeTobaccoSummaryCompositionID: '',
  activeSubstanceSummaryCompositionID: '',
  isAddAlcoholSummary: false,
  isEditAlcoholSummary: false,
  isAddSubstanceSummary: false,
  isAddTobaccoSummary: false,
  isEditTobaccoSummary: false,
  isEditSubstanceSummary: false,
  showAlcoholForm: false,
  showTobaccoForm: false,
  showSubstanceForm: false,
};

const lifestyleSlice = createSlice({
  name: 'lifestyle',
  initialState,
  reducers: {
    updateAlcoholSummary: (state, { payload }) => {
      state.alcoholSummaries = payload;
    },
    updateTobaccoSummary: (state, { payload }) => {
      state.tobaccoSummaries = payload;
    },
    updateSubstanceSummary: (state, { payload }) => {
      state.substanceSummaries = payload;
    },
    updateActiveAlcoholCompositionID: (state, { payload }) => {
      state.activeAlcoholSummaryCompositionID = payload;
    },
    updateActiveTobaccoCompositionID: (state, { payload }) => {
      state.activeTobaccoSummaryCompositionID = payload;
    },
    updateSubstanceSummaryCompositionID: (state, { payload }) => {
      state.activeSubstanceSummaryCompositionID = payload;
    },
    openAddAlcoholSummaryForm: (state) => {
      state.isAddAlcoholSummary = true;
      state.showAlcoholForm = true;
    },
    openAddTobaccoSummaryForm: (state) => {
      state.isAddTobaccoSummary = true;
      state.showTobaccoForm = true;
    },
    openAddSubstanceSummaryForm: (state) => {
      state.isAddSubstanceSummary = true;
      state.showSubstanceForm = true;
    },
    openEditAlcoholSummaryForm: (state) => {
      state.isEditAlcoholSummary = true;
      state.showAlcoholForm = true;
    },
    openEditTobaccoSummaryForm: (state) => {
      state.isEditTobaccoSummary = true;
      state.showTobaccoForm = true;
    },
    openEditSubstanceSummaryForm: (state) => {
      state.isEditSubstanceSummary = true;
      state.showSubstanceForm = true;
    },
    closeAlcoholSummaryForm: (state) => {
      (state.showAlcoholForm = false),
        (state.isAddAlcoholSummary = false),
        (state.isEditAlcoholSummary = false);
    },
    closeTobaccoSummaryForm: (state) => {
      (state.showTobaccoForm = false),
        (state.isAddTobaccoSummary = false),
        (state.isEditTobaccoSummary = false);
    },
    closeSubstanceSummaryForm: (state) => {
      (state.showSubstanceForm = false),
        (state.isAddSubstanceSummary = false),
        (state.isEditSubstanceSummary = false);
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateAlcoholSummary,
  updateSubstanceSummary,
  updateTobaccoSummary,
  openAddTobaccoSummaryForm,
  openAddSubstanceSummaryForm,
  openAddAlcoholSummaryForm,
  updateActiveAlcoholCompositionID,
  updateSubstanceSummaryCompositionID,
  updateActiveTobaccoCompositionID,
  closeTobaccoSummaryForm,
  openEditAlcoholSummaryForm,
  openEditTobaccoSummaryForm,
  openEditSubstanceSummaryForm,
  closeSubstanceSummaryForm,
  closeAlcoholSummaryForm,
  clearState,
} = lifestyleSlice.actions;

export default lifestyleSlice.reducer;
