import { createSlice } from '@reduxjs/toolkit';

type OcularExaminationState = {
  updateOcularExam: null;
  isSaving: false;
};

const initialState: OcularExaminationState = {
  updateOcularExam: null,
  isSaving: false,
};

const OcularExamination = createSlice({
  name: 'ocularExamination',
  initialState,
  reducers: {
    updateOcularExamination: (state, { payload }) => {
      state.updateOcularExam = payload;
    },
    setIsSaving: (state, { payload }) => {
      state.isSaving = payload;
    },
    clearOcularExaminationState: () => {
      return initialState;
    },
  },
});

export const {
  updateOcularExamination,
  setIsSaving,
  clearOcularExaminationState,
} = OcularExamination.actions;
export default OcularExamination.reducer;
