import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pastSurgicalHistory: null,
  isAddPastSurgicalHistory: false,
  isEditPastSurgicalHistory: false,
  showForm: false,
};

const pastSurgicalHistory = createSlice({
  name: 'pastSurgicalHistory',
  initialState,
  reducers: {
    updatePastSurgicalHistory: (state, { payload }) => {
      state.pastSurgicalHistory = payload;
    },
    openAddPastSurgicalHistoryForm: (state) => {
      state.isAddPastSurgicalHistory = true;
      state.isEditPastSurgicalHistory = false;
      state.showForm = true;
    },
    openEditPastSurgicalHistoryForm: (state) => {
      state.isEditPastSurgicalHistory = true;
      state.isAddPastSurgicalHistory = false;
      state.showForm = true;
    },
    closePastSurgicalHistoryForm: (state) => {
      state.isAddPastSurgicalHistory = false;
      state.isEditPastSurgicalHistory = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updatePastSurgicalHistory,
  openAddPastSurgicalHistoryForm,
  openEditPastSurgicalHistoryForm,
  closePastSurgicalHistoryForm,
  clearState,
} = pastSurgicalHistory.actions;
export default pastSurgicalHistory.reducer;
