import { createSlice } from '@reduxjs/toolkit';

type SystemReviewState = {
  ROSForms: {
    'ros-constitutional': boolean;
    'ros-eyes': boolean;
    'ros-ent-mouth': boolean;
    'ros-breast': boolean;
    'ros-respiratory': boolean;
    'ros-cardiovascular': boolean;
    'ros-gastrointestinal': boolean;
    'ros-genitourinary': boolean;
    'ros-musculoskeletal': boolean;
    'ros-neurologic': boolean;
    'ros-skin': boolean;
    'ros-endocrine': boolean;
    'ros-hematologic': boolean;
  };
  activeROSForm: string;
  updateROS:
    | {
        Ehr_id: string;
        Encounter_id: string;
        Composition_uid: string;
        Composition_start_time: string;
        Template_name: string;
      }[]
    | null;
  isSaving: false;
};

const initialState: SystemReviewState = {
  ROSForms: {
    'ros-constitutional': true,
    'ros-eyes': false,
    'ros-ent-mouth': false,
    'ros-breast': false,
    'ros-respiratory': false,
    'ros-cardiovascular': false,
    'ros-gastrointestinal': false,
    'ros-genitourinary': false,
    'ros-musculoskeletal': false,
    'ros-neurologic': false,
    'ros-skin': false,
    'ros-endocrine': false,
    'ros-hematologic': false,
  },
  activeROSForm: 'ros-constitutional',
  updateROS: null,
  isSaving: false,
};

const systemReview = createSlice({
  name: 'systemReview',
  initialState,
  reducers: {
    setActiveForm: (state: any, { payload }) => {
      state.activeROSForm = payload;
      if (payload === 'ros-constitutional') {
        state.ROSForms['ros-constitutional'] = true;
      } else if (payload === 'ros-eyes') {
        state.ROSForms['ros-eyes'] = true;
      } else if (payload === 'ros-ent-mouth') {
        state.ROSForms['ros-ent-mouth'] = true;
      } else if (payload === 'ros-breast') {
        state.ROSForms['ros-breast'] = true;
      } else if (payload === 'ros-respiratory') {
        state.ROSForms['ros-respiratory'] = true;
      } else if (payload === 'ros-cardiovascular') {
        state.ROSForms['ros-cardiovascular'] = true;
      } else if (payload === 'ros-gastrointestinal') {
        state.ROSForms['ros-gastrointestinal'] = true;
      } else if (payload === 'ros-genitourinary') {
        state.ROSForms['ros-genitourinary'] = true;
      } else if (payload === 'ros-musculoskeletal') {
        state.ROSForms['ros-musculoskeletal'] = true;
      } else if (payload === 'ros-neurologic') {
        state.ROSForms['ros-neurologic'] = true;
      } else if (payload === 'ros-skin') {
        state.ROSForms['ros-skin'] = true;
      } else if (payload === 'ros-endocrine') {
        state.ROSForms['ros-endocrine'] = true;
      } else if (payload === 'ros-hematologic') {
        state.ROSForms['ros-hematologic'] = true;
      }
    },
    updateReviewOfSystems: (state, { payload }) => {
      state.updateROS = payload;
    },
    setIsSaving: (state, { payload }) => {
      state.isSaving = payload;
    },
    clearSystemReviewState: () => {
      return initialState;
    },
  },
});

export const {
  setActiveForm,
  updateReviewOfSystems,
  setIsSaving,
  clearSystemReviewState,
} = systemReview.actions;
export default systemReview.reducer;
