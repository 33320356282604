export const NOT_FOUND = 'Resource not found'; // 404 error
export const NOT_AUTHORIZED = 'Authorization failed'; //401 error
export const FORBIDDEN = 'Permission denied'; //403 error
export const BAD_REQUEST = 'Bad request'; //400 error
export const TIME_OUT = 'Request timeout'; //408 error
export const SERVER_DOWN = 'Server is down'; //500 error
export const PATIENT_NOT_FOUND = 'Patient not found!';
export const UNEXPECTED_ERROR =
  'Unexpected error. please contact the system admin';
export const PATIENT_DATA_FETCHING_ERROR = 'Error while fetching patient data.';
export const PATIENT_EXIST = 'Patient with DOB and Phone Number already exist!';
export const FORM_RENDERING_FAIL = 'Form could not be rendered';
export const ERROR_GETTING_EHR_ID = "Error getting patient's ehr id";
export const UNABLE_TO_SAVE_FORM = 'Unable to save form! Please try again';
export const FETCHING_FAILED = 'An error occurred while fetching data';
export const UNPROCESSABLE_ENTITY =
  'Unprocessable entity, please ensure the correct data is sent to the server';
export const UNABLE_TO_UPDATE_PATIENT_RECORD =
  'Unable to update patient record. Please try again';
export const UNABLE_TO_DELETE_FILE = 'Unable to delete file';
export const ERROR_FETCHING_PATIENT_CONTACT = 'Error fetching contact record';

export const INVESTIGATIONS_FETCHING_FAILED = 'Unable to fetch investigations';
export const INVESTIGATION_CREATION_FAILED = 'Unable to create investigation';
export const INVESTIGATION_UPDATION_FAILED = 'Unable to update investigation';
export const INVESTIGATION_UPDATED_SUCCESSFULLY =
  'Investigation updated successfully';
export const INVESTIGATION_DELETION_FAILED = 'Unable to delete investigation';
export const INVESTIGATION_FETCHING_FAILED = 'Unable to fetch investigation';

export const TEST_GROUP_CREATION_FAILED = 'Unable to create test group';
export const TEST_GROUP_UPDATION_FAILED = 'Unable to update test group';
export const TEST_GROUP_UPDATED_SUCCESSFULLY =
  'Test group updated successfully';
export const TEST_GROUP_DELETION_FAILED = 'Unable to delete test group';
export const TEST_GROUPS_FETCHING_FAILED = 'Unable to fetch test groups';
export const TEST_GROUP_FETCHING_FAILED = 'Unable to fetch test group';

export const TEST_PACKAGE_CREATION_FAILED = 'Unable to create test package';
export const TEST_PACKAGE_UPDATION_FAILED = 'Unable to update test package';
export const TEST_PACKAGE_UPDATED_SUCCESSFULLY =
  'Test package updated successfully';
export const TEST_PACKAGE_DELETION_FAILED = 'Unable to delete test package';
export const TEST_PACKAGES_FETCHING_FAILED = 'Unable to fetch test packages';
export const TEST_PACKAGE_FETCHING_FAILED = 'Unable to fetch test package';

export const GROUP_INVESTIGATION_MAPPING_UPDATION_FIELED =
  'Group investigation map updation fieled';
export const GROUP_INVESTIGATION_MAPPING_UPDATED_SUCCESSFULLY =
  'Group investigation map have been updated successfully';
export const GROUP_INVESTIGATION_MAPPING_CREATION_FIELED =
  'Group investigation map creation fieled';
export const GROUP_INVESTIGATION_MAPPING_CREATED_SUCCESSFULLY =
  'Group investigation map have been created successfully';
export const GROUP_INVESTIGATION_MAPPING_DELETION_FEILED =
  'Group investigation map could not be deleted';
export const GROUP_INVESTIGATION_MAPPING_DELETED_SUCCESSFULLY =
  'Group investigation map have been deleted successfully';

export const LABTEST_ORDER_SUCCESS = 'Labtest ordered successfully!';
export const LABTEST_ORDER_FAILED = 'Labtest could not be ordered!';

export const UOMS_FETCHING_FAILED = 'Unable to fetch units of measurements';
export const UOM_CREATION_FAILED = 'Unable to create units of measurement';
export const UOM_UPDATION_FAILED = 'Unable to update units of measurement';
export const UOM_UPDATED_SUCCESSFULLY =
  'units of measurement updated successfully';
export const UOM_DELETION_FAILED = 'Unable to delete units of measurement';
export const UOM_FETCHING_FAILED = 'Unable to fetch units of measurement';

export const PATIENT_ADMISSION_ERROR = 'An error occur while admitting patient';
export const PATIENT_ADMISSION_SUCCESS =
  'Patient have been admitted successfully';
export const PATIENT_ADMISSION_UPDATION_ERROR =
  'An error occur while updating patient admission';
export const PATIENT_ADMISSION_UPDATION_SUCCESS =
  'Patient admission have been updated successfully';

export const PATIENT_ADMISSION_DISCHARGE_SUCCESS =
  'Patient have been discharged from admission successfully';
export const PATIENT_ADMISSION_DISCHARGE_FAILED =
  'Patient admission discharge failed!';

export const NOTE_LOGGED_SUCCESS = 'Note is logged successfully';
export const NOTE_LOGGED_FAILED = 'Note logging failed!';
export const LOGGED_NOTE_FETCHING_FAILED = 'Logged note fetching failed!';

export const PATIENT_HAVE_ACTIVE_TICKET_ERROR =
  'Oops! the patient has an active ticket on the dashboard, please move the active ticket to checkout and then try again';
export const LABTEST_NEEDS_CONSENT_MESSAGE =
  'This Lab Test needs a signed patient consent. Please upload signed consent now.';
export const BLOOD_TRANSFUSION_NEEDS_CONSENT_MESSAGE =
  'This Blood transfusion needs a signed patient consent. Please sign consent now.';
export const LABTEST_NEEDS_SERIAL_MESSAGE =
  'A test serial number needs to be provided at sample collection.';

//Temperature
export const TEMPERATURE_ERROR = 'Error Fetching Temperature data';
export const BMGF_ERROR =
  'this user is not chew and cannot create bmgf initial encounter';

// external API messages
export const UNAUTHORIZED_ERROR_MESSAGE =
  'Your are not authorized to access the requested resource!';
export const MISSING_PARAMETER_ERROR_MESSAGE =
  'Required parameter(s) is/are missing, make sure all required parameters are provided';
export const INVALID_PHONE_NUMBER = 'The provided phone number is not valid';
export const INVALID_DATE_OF_BIRTH = 'The provided date of birth is not valid';
export const INVALID_ENCOUNTER_DATE =
  'The provided encounter date is not valid';
export const PATIENT_RECORD_NOT_FOUND = 'Patient record could not be found';
export const ENCOUNTER_RECORD_NOT_FOUND = 'Encounter record could not be found';

export const FLAG_PATIENT_CREATION_FAILED = 'Unable to flag patient';
export const UNFLAG_PATIENT_CREATION_FAILED = 'Unable to unflag patient';
export const BLOOD_TRANSFUSION_COMPLETED_MESSAGE =
  'Blood Transfusion have been completed successfully';

export const FEATURES_FETCHING_FAILED = 'Unable to fetch features';
export const FEATURE_CREATION_FAILED = 'Unable to create feature';
export const FEATURE_UPDATION_FAILED = 'Unable to update feature';
export const FEATURE_UPDATED_SUCCESSFULLY = 'Feature updated successfully';
export const FEATURE_DELETION_FAILED = 'Unable to delete feature';
export const FEATURE_FETCHING_FAILED = 'Unable to fetch feature';
