import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  vaccinations: [],
  isAddVaccination: false,
  isEditVaccination: false,
  showForm: false,
  vaccineData: [],
  vaccineUpdateData: null,
  showUpdateModal: false,
  showUpdateToaster: false,
};

const vaccinationSlice = createSlice({
  name: 'vaccination',
  initialState,
  reducers: {
    updateVaccinations: (state, { payload }) => {
      state.vaccinations = payload;
    },
    openAddVaccinationForm: (state) => {
      state.isAddVaccination = true;
      state.showForm = true;
    },
    openEditVaccinationForm: (state) => {
      state.isEditVaccination = true;
      state.showForm = true;
    },
    closeVaccinationForm: (state) => {
      state.isAddVaccination = false;
      state.isEditVaccination = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateVaccinations,
  openAddVaccinationForm,
  openEditVaccinationForm,
  closeVaccinationForm,
  clearState,
} = vaccinationSlice.actions;
export default vaccinationSlice.reducer;
