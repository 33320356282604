import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentMedications: [],
  isAddCurrentMedication: false,
  isEditCurrentMedication: false,
  showForm: false,
};

const currentMedicationSlice = createSlice({
  name: 'currentMedication',
  initialState,
  reducers: {
    updateCurrentMedications: (state, { payload }) => {
      state.currentMedications = payload;
    },
    openAddCurrentMedicationForm: (state) => {
      state.isAddCurrentMedication = true;
      state.showForm = true;
    },
    openEditCurrentMedicationForm: (state) => {
      state.isEditCurrentMedication = true;
      state.showForm = true;
    },
    closeCurrentMedicationForm: (state) => {
      state.isAddCurrentMedication = false;
      state.isEditCurrentMedication = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateCurrentMedications,
  openAddCurrentMedicationForm,
  openEditCurrentMedicationForm,
  closeCurrentMedicationForm,
  clearState,
} = currentMedicationSlice.actions;
export default currentMedicationSlice.reducer;
