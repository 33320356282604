import { createSlice } from '@reduxjs/toolkit';

interface ReviewFilter {
  month: Date | null;
  encounterType: string | null;
  physician: {
    code: string;
    value: string;
    label: string;
  } | null;
  nurse: {
    code: string;
    value: string;
    label: string;
  } | null;
  branch: {
    code: string;
    value: string;
    label: string;
  } | null;
}

const initialState: ReviewFilter = {
  branch: null,
  month: new Date(),
  encounterType: '',
  physician: null,
  nurse: null,
};

const reviewFilter = createSlice({
  name: 'reviewFilter',
  initialState,
  reducers: {
    setReviewMonth: (state, { payload }) => {
      state.month = payload;
    },
    setReviewBranchFilter: (state, { payload }) => {
      state.branch = payload;
    },
    setReviewEncounterFilter: (state, { payload }) => {
      state.encounterType = payload;
    },
    setReviewPhysicianFilter: (state, { payload }) => {
      state.physician = payload;
    },
    setReviewNurseFilter: (state, { payload }) => {
      state.nurse = payload;
    },
    clearFilterState: () => {
      return initialState;
    },
  },
});

export const {
  clearFilterState,
  setReviewMonth,
  setReviewBranchFilter,
  setReviewEncounterFilter,
  setReviewPhysicianFilter,
  setReviewNurseFilter,
} = reviewFilter.actions;

export default reviewFilter.reducer;
