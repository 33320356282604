import { createSlice } from '@reduxjs/toolkit';

type VisionSpringState = {
  updateVisionSpring: null;
  isSaving: false;
};

const initialState: VisionSpringState = {
  updateVisionSpring: null,
  isSaving: false,
};

const VisionSpring = createSlice({
  name: 'visionSpring',
  initialState,
  reducers: {
    updateVisionSpringData: (state, { payload }) => {
      state.updateVisionSpring = payload;
    },
    setIsSaving: (state, { payload }) => {
      state.isSaving = payload;
    },
    clearVisionSpringData: () => {
      return initialState;
    },
  },
});

export const { updateVisionSpringData, setIsSaving, clearVisionSpringData } =
  VisionSpring.actions;
export default VisionSpring.reducer;
