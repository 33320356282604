import { createSlice } from '@reduxjs/toolkit';
import { IImagingTest } from '@interfaces/imagingTest';

type ImagingTestStateType = {
  imagingTests: IImagingTest[];
  isAddTest: boolean;
  isEditTest: boolean;
  isEcg: boolean;
  ecgCompositionId: string | null
};

const initialState: ImagingTestStateType = {
  imagingTests: [],
  isAddTest: false,
  isEditTest: false,
  isEcg: false,
  ecgCompositionId: null
};

const imagingTestSlice = createSlice({
  name: 'imagingTests',
  initialState,
  reducers: {
    updateImagingTest: (state, { payload }) => {
      state.imagingTests = payload;
    },
    openAddImagingTestForm: (state) => {
      state.isAddTest = true;
    },
    openEditImagingTestForm: (state) => {
      state.isEditTest = true;
    },
    closeImagingTestForm: (state) => {
      state.isAddTest = false;
      state.isEditTest = false;
    },
    setIsEcg: (state, { payload }) => {
      state.isEcg = payload;
    },
    setEcgCompositionId: (state, { payload }) => {
      state.ecgCompositionId = payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateImagingTest,
  openAddImagingTestForm,
  openEditImagingTestForm,
  closeImagingTestForm,
  setIsEcg,
  setEcgCompositionId,
  clearState,
} = imagingTestSlice.actions;
export default imagingTestSlice.reducer;
