import { createSlice } from '@reduxjs/toolkit';
import { IFeature } from '@settings/interfaces/feature';

type State = {
  features: IFeature[];
};

const initialState: State = {
  features: [],
};

const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatures: (state, { payload }) => {
      state.features = payload;
    },
  },
});

export const { setFeatures } = featuresSlice.actions;
export default featuresSlice.reducer;
