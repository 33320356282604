import { IFluidArray } from '@interfaces/fluid';
import { createSlice } from '@reduxjs/toolkit';

type FluidState = {
  fluidInput: IFluidArray;
  fluidOutput: IFluidArray;
  fluidBalance: Record<string, string>[];
  isAddFluidInput: boolean;
  isAddFluidOutput: boolean;
  isEditFluidInput: boolean;
  isEditFluidOutput: boolean;
  showFluidInputForm: boolean;
  showFluidOutputForm: boolean;
};

const initialState: FluidState = {
  fluidInput: [],
  fluidOutput: [],
  fluidBalance: [],
  isAddFluidInput: false,
  isAddFluidOutput: false,
  isEditFluidInput: false,
  isEditFluidOutput: false,
  showFluidInputForm: false,
  showFluidOutputForm: false,
};

const fluidSlice = createSlice({
  name: 'fluid',
  initialState,
  reducers: {
    updateFluidInput: (state, { payload }) => {
      state.fluidInput = payload;
    },
    updateFluidOutput: (state, { payload }) => {
      state.fluidOutput = payload;
    },
    openAddFluidInput: (state) => {
      state.isAddFluidInput = true;
      state.showFluidInputForm = true;
    },
    openAddFluidOutput: (state) => {
      state.isAddFluidOutput = true;
      state.showFluidOutputForm = true;
    },
    openisEdiFluidInput: (state) => {
      state.isEditFluidInput = true;
      state.showFluidInputForm = true;
    },
    openisEditFluidOutput: (state) => {
      state.isEditFluidOutput = true;
      state.showFluidOutputForm = true;
    },
    closeFluidInput: (state) => {
      state.isAddFluidInput = false;
      state.isEditFluidInput = false;
      state.showFluidInputForm = false;
    },
    closeFluidOutput: (state) => {
      state.isAddFluidOutput = false;
      state.isEditFluidOutput = false;
      state.showFluidOutputForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateFluidInput,
  updateFluidOutput,
  openAddFluidInput,
  openAddFluidOutput,
  openisEdiFluidInput,
  openisEditFluidOutput,
  closeFluidInput,
  closeFluidOutput,
  clearState,
} = fluidSlice.actions;
export default fluidSlice.reducer;
