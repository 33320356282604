import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pastMedicalHistory: [],
  isAddPastMedicalHistory: false,
  isEditPastMedicalHistory: false,
  showForm: false,
};

const pastMedicalHistory = createSlice({
  name: 'pastMedicalHistory',
  initialState,
  reducers: {
    updatePastMedicalHistory: (state, { payload }) => {
      state.pastMedicalHistory = payload;
    },
    openAddPastMedicalHistoryForm: (state) => {
      state.isAddPastMedicalHistory = true;
      state.isEditPastMedicalHistory = false;
      state.showForm = true;
    },
    openEditPastMedicalHistoryForm: (state) => {
      state.isEditPastMedicalHistory = true;
      state.isAddPastMedicalHistory = false;
      state.showForm = true;
    },
    closePastMedicalHistoryForm: (state) => {
      state.isAddPastMedicalHistory = false;
      state.isEditPastMedicalHistory = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updatePastMedicalHistory,
  openAddPastMedicalHistoryForm,
  openEditPastMedicalHistoryForm,
  closePastMedicalHistoryForm,
  clearState,
} = pastMedicalHistory.actions;
export default pastMedicalHistory.reducer;
