import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  anthropometry: null,
  showForm: false,
};

const anthropometry = createSlice({
  name: 'anthropometry',
  initialState,
  reducers: {
    updateAnthropometry: (state, { payload }) => {
      state.anthropometry = payload;
    },
    openAnthropometryForm: (state) => {
      state.showForm = true;
    },
    closeAnthropometryForm: (state) => {
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateAnthropometry,
  openAnthropometryForm,
  closeAnthropometryForm,
  clearState,
} = anthropometry.actions;
export default anthropometry.reducer;
