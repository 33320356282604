import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allergies: null,
  isAddAllergy: false,
  isEditAllergy: false,
  showForm: false,
};

const allergySlice = createSlice({
  name: 'allergy',
  initialState,
  reducers: {
    updateAllergies: (state, { payload }) => {
      state.allergies = payload;
    },
    openAddAllergyForm: (state) => {
      state.isAddAllergy = true;
      state.showForm = true;
    },
    openEditAllergyForm: (state) => {
      state.isEditAllergy = true;
      state.showForm = true;
    },
    closeAllergyForm: (state) => {
      state.isAddAllergy = false;
      state.isEditAllergy = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateAllergies,
  openAddAllergyForm,
  openEditAllergyForm,
  closeAllergyForm,
  clearState,
} = allergySlice.actions;
export default allergySlice.reducer;
