import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showCreateNewAncEncounterModal: false,
  showOrderAncLabtestModal: false,
  showOrderAncLabtestListModal: false,
};

const antenatalCare = createSlice({
  name: 'antenatalCare',
  initialState,
  reducers: {
    toggleShowCreateNewAncEncounterModal: (state, { payload }) => {
      state.showCreateNewAncEncounterModal = payload;
    },
    toggleShowOrderAncLabtestModal: (state, { payload }) => {
      state.showOrderAncLabtestModal = payload;
    },
    toggleShowOrderAncLabtestListModal: (state, { payload }) => {
      state.showOrderAncLabtestListModal = payload;
    },
  },
});

export const {
  toggleShowCreateNewAncEncounterModal,
  toggleShowOrderAncLabtestModal,
  toggleShowOrderAncLabtestListModal,
} = antenatalCare.actions;

export default antenatalCare.reducer;
