import { createSlice } from '@reduxjs/toolkit';

type DentalExaminationState = {
  updateDentalExam: null;
  isSaving: false;
};

const initialState: DentalExaminationState = {
  updateDentalExam: null,
  isSaving: false,
};

const dentalExamination = createSlice({
  name: 'dentalExamination',
  initialState,
  reducers: {
    updateDentalExamination: (state, { payload }) => {
      state.updateDentalExam = payload;
    },
    setIsSaving: (state, { payload }) => {
      state.isSaving = payload;
    },
    clearDentalExaminationState: () => {
      return initialState;
    },
  },
});

export const {
  updateDentalExamination,
  setIsSaving,
  clearDentalExaminationState,
} = dentalExamination.actions;
export default dentalExamination.reducer;
