import { createSlice } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { AppState } from '.';

export interface NextOfKinSliceState {
  nextOfKinData: [] | null;
  savedData: null;
  isAdd: boolean;
  isEdit: boolean;
  isDelete: boolean;
}

const initialState: NextOfKinSliceState = {
  nextOfKinData: null,
  savedData: null,
  isAdd: false,
  isEdit: false,
  isDelete: false,
};

const nextOfKinSlice = createSlice({
  name: 'nextofkin',
  initialState,
  reducers: {
    updateNextOfKinData: (state: NextOfKinSliceState, { payload }: any) => {
      state.nextOfKinData = payload;
    },
    openNextOfKinForm: (state: NextOfKinSliceState) => {
      state.isAdd = true;
      state.isEdit = false;
      state.isDelete = false;
    },
    editNextOfKinForm: (state: NextOfKinSliceState) => {
      state.isAdd = false;
      state.isEdit = true;
      state.isDelete = false;
    },
    DeleteNextOfKin: (state: NextOfKinSliceState) => {
      state.isAdd = false;
      state.isEdit = false;
      state.isDelete = true;
    },
    closeNextOfKinForm: (state: NextOfKinSliceState) => {
      state.isAdd = false;
      state.isEdit = false;
      state.isDelete = false;
    },
    clearNextOfKinState: () => {
      return initialState;
    },
  },
});

export const ehrId = (state: AppState) => state.patient.ehrId;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const {
  updateNextOfKinData,
  openNextOfKinForm,
  editNextOfKinForm,
  DeleteNextOfKin,
  closeNextOfKinForm,
  clearNextOfKinState,
} = nextOfKinSlice.actions;
export default nextOfKinSlice.reducer;
