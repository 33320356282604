import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pregnancySummary: null,
  isAddPregnancySummary: false,
  isEditPregnancySummary: false,
  showForm: false,
};

const pregnancySummary = createSlice({
  name: 'pregnancySummary',
  initialState,
  reducers: {
    updatePregnancySummary: (state, { payload }) => {
      state.pregnancySummary = payload;
    },
    openAddPregnancySummaryForm: (state) => {
      state.isAddPregnancySummary = true;
      state.isEditPregnancySummary = false;
      state.showForm = true;
    },
    openEditPregnancySummaryForm: (state) => {
      state.isEditPregnancySummary = true;
      state.isAddPregnancySummary = false;
      state.showForm = true;
    },
    closePregnancySummaryForm: (state) => {
      state.isAddPregnancySummary = false;
      state.isEditPregnancySummary = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updatePregnancySummary,
  openAddPregnancySummaryForm,
  openEditPregnancySummaryForm,
  closePregnancySummaryForm,
  clearState,
} = pregnancySummary.actions;
export default pregnancySummary.reducer;
