import { createSlice } from '@reduxjs/toolkit';

export type FolderOptionsType = {
  key: string;
  parentId: string;
  title: string;
  type: string;
  path: string[];
  children: FolderOptionsType[];
};

export type FileOptionsType = {
  documentId?: string;
  key?: string;
  uploadedAt: { seconds: number; nanoseconds: number };
  uploadedBy: string;
  parentId?: string;
  patientId: string;
  url?: string;
  path?: string[];
  type?: string;
  title: string;
};

type DocumentStateSlice = {
  currentFolderId: string;
  attachment: {
    url: string;
    name: string;
  };
  currentPath: string[];
  userFiles: any[];
  userFolders: any[];
  currentFile: any;
  isFileView: boolean;
  toggleRefetchFolders: boolean;
};

const initialState: DocumentStateSlice = {
  currentFolderId: '',
  attachment: {
    url: '',
    name: '',
  },
  currentPath: [],
  userFiles: [],
  userFolders: [],
  currentFile: '',
  isFileView: false,
  toggleRefetchFolders: false, //  this causes a use effect in documents.tsx to fire and set the data needed for Tree library to be refreshed
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setFolders: (state, { payload }) => {
      state.userFolders = payload;
    },
    setUserFiles: (state, { payload }) => {
      state.userFiles = payload;
    },
    setCurrentFolder: (state, { payload }) => {
      state.currentFolderId = payload;
    },
    setCurrentPath: (state, { payload }) => {
      state.currentPath = payload;
    },
    setAttachmentUrl: (state, { payload }) => {
      state.attachment.url = payload;
    },
    setAttachmentName: (state, { payload }) => {
      state.attachment.name = payload;
    },
    updateUserFiles: (state, { payload }) => {
      state.userFiles.push(payload);
    },
    deleteFileFromUserFiles: (state, { payload }) => {
      state.userFiles = state.userFiles.filter(
        (item) => item.documentId !== payload,
      );
    },
    setCurrentFile: (state, { payload }) => {
      state.currentFile = payload;
    },
    openFileView: (state) => {
      state.isFileView = true;
    },
    closeFileView: (state) => {
      state.isFileView = false;
    },
    setToggleRefetchFolder: (state) => {
      state.toggleRefetchFolders = !state.toggleRefetchFolders;
    },
    clearDocumentState: () => {
      return initialState;
    },
  },
});

export const {
  setFolders,
  setUserFiles,
  setCurrentPath,
  setAttachmentUrl,
  setAttachmentName,
  updateUserFiles,
  setCurrentFolder,
  setCurrentFile,
  openFileView,
  closeFileView,
  setToggleRefetchFolder,
  deleteFileFromUserFiles,
  clearDocumentState,
} = documentSlice.actions;
export default documentSlice.reducer;
