import { createSlice } from '@reduxjs/toolkit';
export interface Room {
  branch_code: string;
  branch_name: string;
  id: number;
  name: string;
  code: string;
  room_type: string;
  state: string;
  sequence: string;
  write_username?: string;
  is_active?: boolean;
}
export interface Stages {
  id: number;
  name: string;
  selected: boolean;
}
export interface Branches {
  id: number;
  name: string;
  code: string;
  address: string;
  selected: boolean;
}
const roomStages: Stages[] = [];
const allRooms: Room[] = [];
const allStages: Stages[] = [];
const roomId: number | null = null;

const allRoomBranches: Branches[] = [];
const initialState = {
  room: {} as Room,
  allRooms,
  allRoomBranches,
  roomStages,
  allStages,
  filter: {
    state: '',
    branch: '',
    branch_code: '',
  },
  roomToasterCreate: false,
  roomToasterUpdate: false,
  roomToasterDelete: false,
  roomToasterArchive: false,
  roomToasterUnarchive: false,
  roomId,
};

export const roomStateOptions = [
  {
    label: 'clean',
    value: 'clean',
  },
  {
    label: 'occupied',
    value: 'occupied',
  },
  {
    label: 'dirty',
    value: 'dirty',
  },
];

export const roomTypeOptions = [
  {
    label: 'waiting_room',
    value: 'waiting_room',
  },
  {
    label: 'admission',
    value: 'admission',
  },
  {
    label: 'dental',
    value: 'dental',
  },
  {
    label: 'exam_room',
    value: 'exam_room',
  },
  {
    label: 'lab',
    value: 'lab',
  },
  {
    label: 'opthalmology',
    value: 'opthalmology',
  },
  {
    label: 'pharmacy',
    value: 'pharmacy',
  },
  {
    label: 'other_rooms',
    value: 'other_rooms',
  },
  {
    label: 'checkout',
    value: 'checkout',
  },
];
const roomSlice = createSlice({
  name: 'Room',
  initialState,
  reducers: {
    updateTicketRoomId: (state, { payload }) => {
      state.roomId = payload;
    },
    updateAllBranches: (state, { payload }) => {
      state.allRoomBranches = payload;
    },
    updateEachRoom: (state, { payload }) => {
      state.room = payload;
    },
    updateAllRooms: (state, { payload }) => {
      state.allRooms = payload;
    },
    updateRoomStages: (state, { payload }) => {
      state.roomStages = payload;
    },
    updateAllStages: (state, { payload }) => {
      state.allStages = payload;
    },
    setStateFilter: (state, { payload }) => {
      state.filter.state = payload;
    },
    setBranchCodeFilter: (state, { payload }) => {
      state.filter.branch_code = payload;
    },
    createRoomToaster: (state, { payload }) => {
      state.roomToasterCreate = payload;
    },
    updateRoomToaster: (state, { payload }) => {
      state.roomToasterUpdate = payload;
    },
    deleteRoomToaster: (state, { payload }) => {
      state.roomToasterDelete = payload;
    },
    archiveRoomToaster: (state, { payload }) => {
      state.roomToasterArchive = payload;
    },
    unarchiveRoomToaster: (state, { payload }) => {
      state.roomToasterUnarchive = payload;
    },
  },
});
export const {
  updateTicketRoomId,
  updateAllBranches,
  setStateFilter,
  setBranchCodeFilter,
  updateAllRooms,
  updateEachRoom,
  updateAllStages,
  updateRoomStages,
  createRoomToaster,
  updateRoomToaster,
  deleteRoomToaster,
  archiveRoomToaster,
  unarchiveRoomToaster,
} = roomSlice.actions;
export default roomSlice.reducer;
