import { createSlice } from '@reduxjs/toolkit';

export interface INutrition {
  Diet: { value: string };
  Mucous_Membranes: { value: string };
  Recent_weight_Change: { value: string };
  Comment: { value: string };

  Condition_affecting_eating__chewing_and_swallowing: { value: string };
  Composition_uid: string;
}

type NutritionAssessmentState = {
  nutritionAssessment: INutrition;
  showForm: boolean;
};

const initialState: NutritionAssessmentState = {
  nutritionAssessment: {} as INutrition,
  showForm: false,
};

const NutritionAssessmentSlice = createSlice({
  name: 'nutritionAssessment',
  initialState,
  reducers: {
    updateNutritionAssessment: (state, { payload }) => {
      state.nutritionAssessment = payload;
    },
    openNutritionAssessmentForm: (state) => {
      state.showForm = true;
    },
    closeNutritionAssessmentForm: (state) => {
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateNutritionAssessment,
  openNutritionAssessmentForm,
  closeNutritionAssessmentForm,
  clearState,
} = NutritionAssessmentSlice.actions;

export default NutritionAssessmentSlice.reducer;
