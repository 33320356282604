import jwt_decode from 'jwt-decode';

type keyValuePair = { [key: string]: string };

export default class Keycloak {
  token: string;
  decodedJWT: keyValuePair;

  constructor(token: string) {
    this.token = token;
    this.decodedJWT = token !== undefined ? jwt_decode(token) : {};
  }

  /**
   *
   * @returns Resource access object extracted from sessionToken
   */
  getResourceAccess = async (): Promise<keyValuePair> => {
    const decodedToken = this.decodedJWT;
    let accessRights = {};
    if (decodedToken) {
      Object.keys(decodedToken).map((key) => {
        if (key === 'resource_access') {
          accessRights = decodedToken[key];
        }
      });
    }
    return Promise.resolve(accessRights);
  };

  /**
   *
   * @param clients
   * @returns Array of roles mapped to the clients in keycloak
   */
  extractClientRoles = async (
    clients: Array<string>,
  ): Promise<Array<string>> => {
    const resourceAccess = (await this.getResourceAccess()) as any;

    const rolesArr: Array<string> = [];
    for (const client of clients) {
      if (Object.keys(resourceAccess).includes(client)) {
        const roles = resourceAccess[client]['roles'];
        for (const role of roles) {
          switch (client) {
            case 'ehr-server':
              rolesArr.push(`EHR_${role}`); //eg. EHR_ROLE_WRITE_EHR
              break;
            case 'demographics-server':
              rolesArr.push(`${role}`); //eg. DEMOGRAPHICS_ADMIN
              break;
            case 'ehacare-patientflow':
              rolesArr.push(`${role}`); //eg. PATIENTFLOW_TICKET_VIEW
              break;
            case 'ehacare-booking':
              rolesArr.push(`${role}`); //eg. BOOKING_VIEW
              break;
            case 'ehacare':
              rolesArr.push(`${role}`); //eg. LAB_VIEW, PRESCRIPTION_VIEW
              break;
            default:
            //nothing
          }
        }
      }
    }
    return Promise.resolve(rolesArr);
  };

  /**
   *
   * @returns list of keycloak permissions/roles mapped to the user token
   */
  getUserRoles = async (): Promise<Array<string>> => {
    const clientList = [
      'ehr-server',
      'demographics-server',
      'ehacare-patientflow',
      'ehacare-booking',
      'ehacare',
    ];
    const permissions = await this.extractClientRoles(clientList);
    return Promise.resolve(permissions);
  };

  /**
   *
   * @returns list of keycloak user groups from the user token
   */
  getUserGroups = async (): Promise<Array<string>> => {
    return this.decodedJWT.groups as unknown as string[];
  };

  /**
   *
   * @param role
   * @returns  true if user has the provided role
   */
  checkAccessRight = async (role: string): Promise<boolean> => {
    /**
     * role should be in format EHR_ROLE_VIEW_ADMIN
     */
    const tokenRoles = await this.getUserRoles();
    let hasPerm = false;
    if (tokenRoles.includes(role)) {
      hasPerm = true;
    }
    return Promise.resolve(hasPerm);
  };
}
