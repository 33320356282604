import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import encounterReducer from '@store/EncounterSlice';
import patientReducer from '@store/PatientSlice';
import socialHistoryReducer from '@store/socialHistorySlice';
import allergyReducer from '@store/AllergySlice';
import currentMedicationReducer from '@store/CurrentMedicationSlice';
import vitalSignReducer from '@store/VitalSignSlice';
import fallRiskAssessmentReducer from '@store/FallRiskAssessmentSlice';
import anthropometryReducer from '@store/AnthropometrySlice';
import patientExaminationReducer from '@store/PatientExaminationSlice';
import vaccinationReducer from '@store/VaccinationSlice';
import patientFlowReducer from '@store/PatientFlowSlice';
import patientFlowTicketReducer from '@store/PatientFlowTicketSlice';
import clinicsReducer from '@store/ClinicsSlice';
import careProviderReducer from '@store/CareProviderSlice';
import LifestyleReducer from '@store/lifestyleSlice';
import healthCareServiceReducer from '@store/HealthCareServiceSlice';
import searchedPatientReducer from '@store/SearchedPatientSlice';
import clientReducer from '@store/ClientSlice';
import calendarReducer from '@store/CalendarSlice';
import PastMedicalHistoryReducer from '@store/PastMedicalHistorySlice';
import FamilyHistoryReducer from '@store/FamilyHistorySlice';
import PastSurgicalHistoryReducer from '@store/PastSurgicalHistorySlice';
import ObstericHistoryReducer from '@store/ObstericHistorySlice';
import GynaecologySummaryReducer from '@store/GynaecologySummarySlice';
import PregnancySummaryReducer from '@store/PregnancySummary';
import PhysicalExaminationReducer from '@store/PhysicalExamination';
import SystemReviewReducer from './SystemReviewSlice';
import NextOfKinReducer from '@store/NextOfKinSlice';
import FormRendererReducer from '@store/FormRenderer';
import ProcedureReducer from '@store/ProcedureSlice';
import patientInsuranceSlice from '@store/PatientInsuranceSlice';
import ImagingReducer from '@store/ImagingTest';
import ReferralReducer from '@store/ReferralsSlice';
import DocumentReducer from '@store/DocumentSlice';
import CarePlanReducer from '@store/CarePlanSlice';
import FollowUpAppointmentReducer from '@store/FollowUpAppointmentSlice';
import UserReducer from '@store/UserSlice';
import RoomReducer from '@store/RoomSlice';
import StageReducer from '@store/StageSlice';
import labReducer from '@store/LabSlice';
import messageReducer from '@store/MessageSlice';
import BloodGroupReducer from '@store/BloodGroupSlice';
import LabOrderReducer from '@store/LabOrderSlice';
import PrescriptionReducer from '@store/Prescription';
import NutritionReducer from '@store/NutritionAssessmentSlice';
import PainReducer from '@store/PainAssessmentSlice';
import ContraceptiveReducer from '@store/contraceptiveUseSlice';
import PlanReducer from '@store/PlanSlice';
import admissionReducer from '@store/AdmissionSlice';
import TabsSlice from '@store/TabsSlice';
import DentalExaminationSlice from '@store/DentalExaminationSlice';
import OcularExaminationSlice from '@store/OcularExaminationSlice';
import PastDentalHistoryReducer from '@store/PastDentalHistorySlice';
import PastOcularHistoryReducer from '@store/pastOcularHistorySlice';
import FilterReducer from '@store/filterSlice';
import MedicationAdministrationReducer from '@store/medicationAdministered';
import FluidReducer from '@store/FluidSlice';
import VisionSpringReducer from '@store/VisionSpringSlice';
import LogNoteReducer from '@store/LogNoteSlice';
import MedicalReportReducer from '@store/MedicalReportSlice';

import NewReviewReducer from '../quality-app/features/chart-audits/store/newReview';
import ReviewFilterReducer from '../quality-app/features/chart-audits/store/reviewFilters';
import ListFiltersReducer from '../quality-app/store/listFilters';
import ChartReviewReducer from '../quality-app/features/chart-audits/store/chartReview';
import ChatAppReducer from '../chat-app/store/chatappSlice';
import BMGFStudyReducer from '../bmgf/store';
import AntenatalCareReducer from './AntenatalCareSlice';

import SettingsReducer from '@settings/store/settingsSlice';
import FeatureSettingsReducer from '@settings/store/featureSettingsSlice';

export const store = configureStore({
  reducer: {
    encounter: encounterReducer,
    patient: patientReducer,
    allergy: allergyReducer,
    currentMedication: currentMedicationReducer,
    vitalSign: vitalSignReducer,
    fallRiskAssessment: fallRiskAssessmentReducer,
    anthropometry: anthropometryReducer,
    vaccination: vaccinationReducer,
    patientExamination: patientExaminationReducer,
    patientFlow: patientFlowReducer,
    patientFlowTicket: patientFlowTicketReducer,
    clinics: clinicsReducer,
    careProvider: careProviderReducer,
    healthCareService: healthCareServiceReducer,
    searchedPatient: searchedPatientReducer,
    client: clientReducer,
    calendar: calendarReducer,
    pastMedicalHistory: PastMedicalHistoryReducer,
    pastSurgicalHistory: PastSurgicalHistoryReducer,
    familyHistory: FamilyHistoryReducer,
    obstetricHistory: ObstericHistoryReducer,
    gynaecologySummary: GynaecologySummaryReducer,
    pregnancySummary: PregnancySummaryReducer,
    physicalExamination: PhysicalExaminationReducer,
    systemReview: SystemReviewReducer,
    nextofkin: NextOfKinReducer,
    formRenderer: FormRendererReducer,
    procedure: ProcedureReducer,
    patientInsurance: patientInsuranceSlice,
    imagingTest: ImagingReducer,
    referral: ReferralReducer,
    carePlan: CarePlanReducer,
    document: DocumentReducer,
    followUpAppointment: FollowUpAppointmentReducer,
    user: UserReducer,
    room: RoomReducer,
    stage: StageReducer,
    lifestyle: LifestyleReducer,
    lab: labReducer,
    message: messageReducer,
    bloodGroup: BloodGroupReducer,
    labOrder: LabOrderReducer,
    prescription: PrescriptionReducer,
    nutrition: NutritionReducer,
    pain: PainReducer,
    socialHistory: socialHistoryReducer,
    contraceptive: ContraceptiveReducer,
    filter: FilterReducer,
    plan: PlanReducer,
    admission: admissionReducer,
    tabs: TabsSlice,
    dentalExamination: DentalExaminationSlice,
    ocularExamination: OcularExaminationSlice,
    pastDentalHistory: PastDentalHistoryReducer,
    pastOcularHistory: PastOcularHistoryReducer,
    medicationAdministered: MedicationAdministrationReducer,
    fluid: FluidReducer,
    visionSpring: VisionSpringReducer,
    logNote: LogNoteReducer,
    bmgfStudy: BMGFStudyReducer,
    newReview: NewReviewReducer,
    reviewFilter: ReviewFilterReducer,
    listFilters: ListFiltersReducer,
    chartReview: ChartReviewReducer,
    medicalReport: MedicalReportReducer,
    chatApp: ChatAppReducer,
    settings: SettingsReducer,
    antenatalCare: AntenatalCareReducer,
    features: FeatureSettingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const makeStore = () => store;

export const wrapper = createWrapper(makeStore);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
