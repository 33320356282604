import { createSlice } from '@reduxjs/toolkit';
import { IEncounter } from '@interfaces/encounter';

type FilterType = {
  service: string;
  encounterType: string;
};

type EncounterStateType = {
  encounterList: IEncounter[];
  activeEncounter: IEncounter | null;
  count: number;
  isAddEncounter: boolean;
  isEditEncounter: boolean;
  showForm: boolean;
  patientPreviousEncounterId: string;
  isAssessmentView: boolean;
  isExaminationView: boolean;
  isDiagnosisAndTreatmentPlan: boolean;
  isProcedureView: boolean;
  isBloodTransfusionView: boolean;
  isImagingTest: boolean;
  isSummaryView: boolean;
  diagnosisRefetchSwitch: boolean;
  filter: FilterType;
};

const initialState: EncounterStateType = {
  encounterList: [],
  activeEncounter: null,
  count: 0,
  isAddEncounter: false,
  isEditEncounter: false,
  showForm: false,
  patientPreviousEncounterId: '',
  isAssessmentView: true,
  isExaminationView: false,
  isDiagnosisAndTreatmentPlan: false,
  isProcedureView: false,
  isBloodTransfusionView: false,
  isImagingTest: false,
  isSummaryView: false,
  diagnosisRefetchSwitch: false,
  filter: {
    service: '',
    encounterType: '',
  },
};
const encounterSlice = createSlice({
  name: 'encounter',
  initialState,
  reducers: {
    updateEncounterList: (state, { payload }) => {
      state.encounterList = payload;
    },
    updateEncounterCount: (state, { payload }) => {
      state.count = payload;
    },
    setActiveEncounter: (state, { payload }) => {
      state.activeEncounter = state.encounterList.filter(
        (encounter: IEncounter) => encounter.Encounter_id === payload,
      )[0];
    },
    updateActiveEncounter: (state, { payload }) => {
      state.activeEncounter = payload;
    },
    setPatientPreviousEncounterId: (state, { payload }) => {
      state.patientPreviousEncounterId = payload;
    },
    openAddEncounterForm: (state) => {
      state.isAddEncounter = true;
      state.showForm = true;
    },
    openEditEncounterForm: (state) => {
      state.isEditEncounter = true;
      state.showForm = true;
    },
    closeEncounterForm: (state) => {
      state.isAddEncounter = false;
      state.isEditEncounter = false;
      state.showForm = false;
    },
    activatePatientAssessmentView: (state) => {
      state.isExaminationView = false;
      state.isDiagnosisAndTreatmentPlan = false;
      state.isProcedureView = false;
      state.isBloodTransfusionView = false;
      state.isImagingTest = false;
      state.isAssessmentView = true;
      state.isSummaryView = false;
    },
    activatePatientExaminationView: (state) => {
      state.isAssessmentView = false;
      state.isDiagnosisAndTreatmentPlan = false;
      state.isProcedureView = false;
      state.isBloodTransfusionView = false;
      state.isImagingTest = false;
      state.isExaminationView = true;
      state.isSummaryView = false;
    },
    activateDiagnosisAndTreatmentPlanView: (state) => {
      state.isAssessmentView = false;
      state.isExaminationView = false;
      state.isDiagnosisAndTreatmentPlan = true;
      state.isProcedureView = false;
      state.isBloodTransfusionView = false;
      state.isImagingTest = false;
      state.isSummaryView = false;
    },
    activateProcedureView: (state) => {
      state.isAssessmentView = false;
      state.isExaminationView = false;
      state.isDiagnosisAndTreatmentPlan = false;
      state.isProcedureView = true;
      state.isBloodTransfusionView = false;
      state.isImagingTest = false;
      state.isSummaryView = false;
    },
    activateBloodTransfusionView: (state) => {
      state.isAssessmentView = false;
      state.isExaminationView = false;
      state.isDiagnosisAndTreatmentPlan = false;
      state.isProcedureView = false;
      state.isBloodTransfusionView = true;
      state.isImagingTest = false;
      state.isSummaryView = false;
    },
    activateImagingTestView: (state) => {
      state.isAssessmentView = false;
      state.isExaminationView = false;
      state.isDiagnosisAndTreatmentPlan = false;
      state.isProcedureView = false;
      state.isBloodTransfusionView = false;
      state.isImagingTest = true;
      state.isSummaryView = false;
    },
    activateSummaryView: (state) => {
      state.isAssessmentView = false;
      state.isExaminationView = false;
      state.isDiagnosisAndTreatmentPlan = false;
      state.isProcedureView = false;
      state.isBloodTransfusionView = false;
      state.isImagingTest = false;
      state.isSummaryView = true;
    },
    toogleDiagnosisRefetch: (state) => {
      state.diagnosisRefetchSwitch = !state.diagnosisRefetchSwitch;
    },
    setSeviceFilter: (state, { payload }) => {
      state.filter.service = payload;
    },
    setEncounterTypeFilter: (state, { payload }) => {
      state.filter.encounterType = payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateEncounterList,
  updateEncounterCount,
  setActiveEncounter,
  updateActiveEncounter,
  openAddEncounterForm,
  openEditEncounterForm,
  closeEncounterForm,
  activatePatientAssessmentView,
  activatePatientExaminationView,
  activateDiagnosisAndTreatmentPlanView,
  activateProcedureView,
  activateBloodTransfusionView,
  activateImagingTestView,
  setPatientPreviousEncounterId,
  activateSummaryView,
  toogleDiagnosisRefetch,
  setSeviceFilter,
  setEncounterTypeFilter,
  clearState,
} = encounterSlice.actions;

export default encounterSlice.reducer;
