import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gynaecologySummary: null,
  showForm: false,
};

const gynaecologySummary = createSlice({
  name: 'gynaecologySummary',
  initialState,
  reducers: {
    updateGynaecologySummary: (state, { payload }) => {
      state.gynaecologySummary = payload;
    },
    openGynaecologySummaryForm: (state) => {
      state.showForm = true;
    },
    closeGynaecologySummaryForm: (state) => {
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateGynaecologySummary,
  openGynaecologySummaryForm,
  closeGynaecologySummaryForm,
  clearState,
} = gynaecologySummary.actions;
export default gynaecologySummary.reducer;
