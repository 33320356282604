import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  procedures: [],
  proceduresCount: 0,
  isAddProcedure: false,
  isEditProcedure: false,
  showForm: false,
  filter: {
    search: '',
  },
};

const procedureSlice = createSlice({
  name: 'procedures',
  initialState,
  reducers: {
    updateProcedures: (state, { payload }) => {
      state.procedures = payload;
    },
    setProceduresCount: (state, { payload }) => {
      state.proceduresCount = payload;
    },
    openAddProcedureForm: (state) => {
      state.isAddProcedure = true;
      state.showForm = true;
    },
    openEditProcedureForm: (state) => {
      state.isEditProcedure = true;
      state.showForm = true;
    },
    closeProcedureForm: (state) => {
      state.isAddProcedure = false;
      state.isEditProcedure = false;
      state.showForm = false;
    },
    setSearchFilter: (state, { payload }) => {
      state.filter.search = payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateProcedures,
  setProceduresCount,
  openAddProcedureForm,
  openEditProcedureForm,
  closeProcedureForm,
  setSearchFilter,
  clearState,
} = procedureSlice.actions;
export default procedureSlice.reducer;
