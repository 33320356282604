import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

export const init = (): void => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];

    integrations.push(new Integrations.BrowserTracing());
    Sentry.init({
      enabled: process.env.NEXT_PUBLIC_APP_STAGE !== 'development',
      environment: process.env.NEXT_PUBLIC_APP_STAGE,
      integrations,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: `${process.env.NEXT_PUBLIC_COMMIT_SHA}`,
      tracesSampleRate: 1.0,
    });
    Sentry.configureScope((scope) => {
      scope.setTag('stage', process.env.NEXT_PUBLIC_APP_STAGE);
    });
  }
};

export default Sentry;
