import { createSlice } from '@reduxjs/toolkit';

type PhysicalExaminationState = {
  ExamForms: {
    'exam-constitutional': boolean;
    'exam-eyes': boolean;
    'exam-ear-nose-mouth-throat': boolean;
    'exam-breast': boolean;
    'exam-respiratory': boolean;
    'exam-cardiovascular': boolean;
    'exam-gastrointestinal': boolean;
    'exam-genitourinary': boolean;
    'exam-lymphatic': boolean;
    'exam-musculoskeletal': boolean;
    'exam-skin': boolean;
    'exam-neurologic': boolean;
    'exam-psychiatric': boolean;
  };
  activeExamForm: string;
  updateExams:
    | {
        Ehr_id: string;
        Encounter_id: string;
        Composition_uid: string;
        Composition_start_time: string;
        Template_name: string;
      }[]
    | null;
  isSaving: false;
};

const initialState: PhysicalExaminationState = {
  ExamForms: {
    'exam-constitutional': false,
    'exam-eyes': false,
    'exam-ear-nose-mouth-throat': false,
    'exam-breast': false,
    'exam-respiratory': false,
    'exam-cardiovascular': false,
    'exam-gastrointestinal': false,
    'exam-genitourinary': false,
    'exam-lymphatic': false,
    'exam-musculoskeletal': false,
    'exam-skin': false,
    'exam-neurologic': false,
    'exam-psychiatric': false,
  },
  activeExamForm: 'exam-constitutional',
  updateExams: null,
  isSaving: false,
};

const physicalExamination = createSlice({
  name: 'physicalExamination',
  initialState,
  reducers: {
    setActiveForm: (state: any, { payload }) => {
      state.activeExamForm = payload;
      if (payload === 'exam-constitutional') {
        state.ExamForms['exam-constitutional'] = true;
      } else if (payload === 'exam-eye') {
        state.ExamForms['exam-eye'] = true;
      } else if (payload === 'exam-ear-nose-mouth-throat') {
        state.ExamForms['exam-ear-nose-mouth-throat'] = true;
      } else if (payload === 'exam-breast') {
        state.ExamForms['exam-breast'] = true;
      } else if (payload === 'exam-respiratory') {
        state.ExamForms['exam-respiratory'] = true;
      } else if (payload === 'exam-cardiovascular') {
        state.ExamForms['exam-cardiovascular'] = true;
      } else if (payload === 'exam-gastrointestinal') {
        state.ExamForms['exam-gastrointestinal'] = true;
      } else if (payload === 'exam-genitourinary') {
        state.ExamForms['exam-genitourinary'] = true;
      } else if (payload === 'exam-musculoskeletal') {
        state.ExamForms['exam-musculoskeletal'] = true;
      } else if (payload === 'exam-skin') {
        state.ExamForms['exam-skin'] = true;
      } else if (payload === 'exam-neurologic') {
        state.ExamForms['exam-neurologic'] = true;
      } else if (payload === 'exam-psychiatric') {
        state.ExamForms['exam-psychiatric'] = true;
      }
    },
    updatePhysicalExamination: (state, { payload }) => {
      state.updateExams = payload;
    },
    setIsSaving: (state, { payload }) => {
      state.isSaving = payload;
    },
    clearPhysicalExaminationState: () => {
      return initialState;
    },
  },
});

export const {
  setActiveForm,
  updatePhysicalExamination,
  setIsSaving,
  clearPhysicalExaminationState,
} = physicalExamination.actions;
export default physicalExamination.reducer;
