import {
  IAllChannelsForUser,
  IChannelCreateResp,
} from '@chatapp-interfaces/channel';
import {
  IChannelMessageSendDelNotif,
  IMessage,
  IRoomMessageSendDelNotif,
} from '@chatapp-interfaces/message';
import { IUserRoom } from '@chatapp-interfaces/room';
import { createSlice } from '@reduxjs/toolkit';

type ChatAppSlice = {
  showAddMemberModal: boolean;
  showCreateChannelModal: boolean;
  showLeaveChannelModal: boolean;
  settingsSection: string;
  showCreateUserAccessModal: boolean;
  showCreateTemplateModal: boolean;
  isEditTemplate: boolean;
  showDeleteUserModal: boolean;
  showSelectTemplateModal: boolean;
  sidebarExpand: boolean;
  showMiniChatapp: boolean;
  currentSection: string;
  chatAppNavKey: string;
  chatAppNavId: string;

  showDeleteMessageModal: boolean;
  ////////////// Message Room ////////////////////

  messageRoomMembers: IUserRoom[];
  messageToDelete: IRoomMessageSendDelNotif | null;
  currentRoomName: string;
  roomMessages: Array<IMessage>;
  roomUnreadMessages: Record<string, number> | {};
  fireDeleteWSEvent: boolean;

  ////////////// Channel ////////////////
  channelUnreadMessages: Record<string, number> | null;
  channelsUserIsIn: IChannelCreateResp[];
  currentChannelObject: IChannelCreateResp | null;
  channelToLeaveorJoin: IAllChannelsForUser | null;
  channelMessageToDelete: IChannelMessageSendDelNotif | null;
  showLeaveChannelToaster: boolean;
  usersAddedToChannel: string[];
};

const initialState: ChatAppSlice = {
  showAddMemberModal: false,
  showCreateChannelModal: false,
  showLeaveChannelModal: false,
  settingsSection: 'Users Access',
  showCreateUserAccessModal: false,
  showCreateTemplateModal: false,
  isEditTemplate: false,
  showDeleteUserModal: false,
  showSelectTemplateModal: false,
  sidebarExpand: false,
  showMiniChatapp: false,
  currentSection: 'navigation',
  chatAppNavKey: '',
  chatAppNavId: '',
  roomMessages: [],

  ////////////// Message Room ///////////////////////
  messageRoomMembers: [],
  currentRoomName: '',
  roomUnreadMessages: {},
  showDeleteMessageModal: false,
  messageToDelete: null,
  fireDeleteWSEvent: false,

  ////////////// Channel ////////////////
  channelUnreadMessages: null,
  channelsUserIsIn: [],
  currentChannelObject: null,
  channelToLeaveorJoin: null,
  channelMessageToDelete: null,
  showLeaveChannelToaster: false,
  usersAddedToChannel: [],
};

const chatappSlice = createSlice({
  name: 'chatapp',
  initialState,
  reducers: {
    setShowAddMemberModal: (state, { payload }) => {
      state.showAddMemberModal = payload;
    },
    setShowCreateChannelModal: (state, { payload }) => {
      state.showCreateChannelModal = payload;
    },
    setShowLeaveChannelModal: (state, { payload }) => {
      state.showLeaveChannelModal = payload;
    },
    setSettingsSection: (state, { payload }) => {
      state.settingsSection = payload;
    },
    setShowCreateUserAccessModal: (state, { payload }) => {
      state.showCreateUserAccessModal = payload;
    },
    setShowCreateTemplateModal: (state, { payload }) => {
      state.showCreateTemplateModal = payload;
    },
    setIsEditTemplate: (state, { payload }) => {
      state.isEditTemplate = payload;
    },
    setShowDeleteUserModal: (state, { payload }) => {
      state.showDeleteUserModal = payload;
    },
    setShowSelectTemplateModal: (state, { payload }) => {
      state.showSelectTemplateModal = payload;
    },
    setSidebarExpand: (state, { payload }) => {
      state.sidebarExpand = payload;
    },
    setShowMiniChatapp: (state, { payload }) => {
      state.showMiniChatapp = payload;
    },
    setCurrentSection: (state, { payload }) => {
      state.currentSection = payload;
    },
    setChatappNavKey: (state, { payload }) => {
      state.chatAppNavKey = payload;
    },
    setChatappNavId: (state, { payload }) => {
      state.chatAppNavId = payload;
    },

    ////////////////Message Room ///////////////////////

    setMessageRoomMembers: (state, { payload }) => {
      state.messageRoomMembers = payload;
    },
    updateMessageRoomMembers: (state, { payload }) => {
      state.messageRoomMembers = [payload, ...state.messageRoomMembers];
    },
    setCurrentRoomName: (state, { payload }) => {
      state.currentRoomName = payload;
    },
    setRoomMessages: (state, { payload }) => {
      state.roomMessages = payload;
    },
    updateRoomMessages: (state, { payload }) => {
      state.roomMessages = [...state.roomMessages, payload];
    },

    deleteRoomMessage: (state, { payload }) => {
      state.roomMessages = state.roomMessages.filter(
        (item) => Number(item.timestamp) !== payload.timestamp,
      );
    },

    setRoomUnreadMessage: (state, { payload }) => {
      state.roomUnreadMessages = {
        ...state.roomUnreadMessages,
        [payload?.room_id]: payload?.unread_count,
      };
    },

    updateRoomUnreadMessage: (state, { payload }) => {
      state.roomUnreadMessages = {
        ...state.roomUnreadMessages,
        [payload]:
          state.roomUnreadMessages[
            payload as keyof typeof state.roomUnreadMessages
          ] + 1,
      };
    },

    setNewRoomUnreadMessages: (state, { payload }) => {
      state.roomUnreadMessages = {
        ...state.roomUnreadMessages,
        [payload]: 1,
      };
    },

    setShowDeleteMessageModal: (state, { payload }) => {
      state.showDeleteMessageModal = payload;
    },
    setMessageToDelete: (state, { payload }) => {
      state.messageToDelete = payload;
    },

    setFireDeleteWSEvent: (state, { payload }) => {
      state.fireDeleteWSEvent = payload;
    },

    ////////////// Channel ////////////////
    setChannelsUserIsIn: (state, { payload }) => {
      state.channelsUserIsIn = payload;
    },

    updateChannelsUserIsIn: (state, { payload }) => {
      state.channelsUserIsIn = [...state.channelsUserIsIn, payload];
    },

    setChannelUnreadMessages: (state, { payload }) => {
      state.channelUnreadMessages = {
        ...state.channelUnreadMessages,
        [payload?.channel_id]: payload?.unread_count,
      };
    },
    setChannelUnreadMessagesToNull: (state) => {
      state.channelUnreadMessages = null;
    },

    removeChannelUnreadMessage: (state, { payload }) => {
      state.channelUnreadMessages &&
        delete state.channelUnreadMessages[payload];
    },

    removeChannelUserIsIn: (state, { payload }) => {
      state.channelsUserIsIn = state.channelsUserIsIn.filter(
        (item) => item !== payload,
      );
    },

    setNewChannelUnreadMessages: (state, { payload }) => {
      state.channelUnreadMessages = {
        ...state.channelUnreadMessages,
        [payload]: 1,
      };
    },

    updateChannelUnreadMessages: (state, { payload }) => {
      state.channelUnreadMessages = {
        ...state.channelUnreadMessages,
        [payload]:
          state.channelUnreadMessages![
            payload as keyof typeof state.channelUnreadMessages
          ] + 1,
      };
    },

    setCurrentChannelObject: (state, { payload }) => {
      state.currentChannelObject = payload;
    },

    setChannelToLeaveorJoin: (state, { payload }) => {
      state.channelToLeaveorJoin = payload;
    },
    setChannelMessageToDelete: (state, { payload }) => {
      state.channelMessageToDelete = payload;
    },

    deleteChannelMessage: (state, { payload }) => {
      state.roomMessages = state.roomMessages.filter(
        (item) => Number(item.timestamp) !== payload.timestamp,
      );
    },

    setShowLeaveChannelToaster: (state, { payload }) => {
      state.showLeaveChannelToaster = payload;
    },

    setUsersAddedToChannel: (state, { payload }) => {
      state.usersAddedToChannel = payload;
    },
  },
});

export const {
  setShowAddMemberModal,
  setShowCreateChannelModal,
  setShowLeaveChannelModal,
  setSettingsSection,
  setShowCreateUserAccessModal,
  setShowCreateTemplateModal,
  setIsEditTemplate,
  setShowDeleteUserModal,
  setShowSelectTemplateModal,
  setSidebarExpand,
  setShowMiniChatapp,
  setCurrentSection,
  setChatappNavKey,
  setChatappNavId,
  ////////////// Room ///////////////////////
  setMessageRoomMembers,
  updateMessageRoomMembers,
  setCurrentRoomName,
  setRoomMessages,
  updateRoomMessages,
  deleteRoomMessage,
  setRoomUnreadMessage,
  updateRoomUnreadMessage,
  setNewRoomUnreadMessages,
  setShowDeleteMessageModal,
  setMessageToDelete,
  setFireDeleteWSEvent,
  //////////////////// Channel ////////////////
  setChannelsUserIsIn,
  updateChannelsUserIsIn,
  setCurrentChannelObject,
  setChannelToLeaveorJoin,
  setChannelUnreadMessages,
  updateChannelUnreadMessages,
  setChannelMessageToDelete,
  deleteChannelMessage,
  setShowLeaveChannelToaster,
  setNewChannelUnreadMessages,
  setUsersAddedToChannel,
  setChannelUnreadMessagesToNull,
  removeChannelUnreadMessage,
  removeChannelUserIsIn,
} = chatappSlice.actions;
export default chatappSlice.reducer;
