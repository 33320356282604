import { createSlice } from '@reduxjs/toolkit';

export interface userSState {
  isAdd: boolean;
  isEdit: boolean;
  isDelete: boolean;
  isUserProfile: boolean;
}

const initialState: userSState = {
  isAdd: false,
  isEdit: false,
  isDelete: false,
  isUserProfile: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    openUserForm: (state) => {
      state.isAdd = true;
      state.isEdit = false;
      state.isDelete = false;
      state.isUserProfile = false;
    },
    editUserForm: (state) => {
      state.isAdd = false;
      state.isEdit = true;
      state.isDelete = false;
      state.isUserProfile = false;
    },
    setUserProfile: (state) => {
      state.isAdd = false;
      state.isEdit = true;
      state.isDelete = false;
      state.isUserProfile = true;
    },
    closeUserForm: (state) => {
      state.isAdd = false;
      state.isEdit = false;
      state.isDelete = false;
      state.isUserProfile = false;
    },
  },
});

export const { openUserForm, editUserForm, setUserProfile, closeUserForm } =
  userSlice.actions;
export default userSlice.reducer;
