import { createSlice } from '@reduxjs/toolkit';

type LabType = {
  uom: {
    isAddUOM: boolean;
  };
  investigation: {
    isAddInvestigation: boolean;
  };
  testGroups: {
    isAddTestGroup: boolean;
  };
  testPackages: {
    isAddTestPackage: boolean;
  };
};

const initialState: LabType = {
  uom: {
    isAddUOM: false,
  },
  investigation: {
    isAddInvestigation: false,
  },
  testGroups: {
    isAddTestGroup: false,
  },
  testPackages: {
    isAddTestPackage: false,
  },
};

const labSlice = createSlice({
  name: 'labSlice',
  initialState,
  reducers: {
    openAddUOMView: (state) => {
      state.uom.isAddUOM = true;
    },
    openAddInvestigationView: (state) => {
      state.investigation.isAddInvestigation = true;
    },
    openAddTestGroupView: (state) => {
      state.testGroups.isAddTestGroup = true;
    },
    openAddTestPackagesView: (state) => {
      state.testPackages.isAddTestPackage = true;
    },
    closeAddUOMView: (state) => {
      state.uom.isAddUOM = false;
    },
    closeAddInvestigationView: (state) => {
      state.investigation.isAddInvestigation = false;
    },
    closeAddTestGroupView: (state) => {
      state.testGroups.isAddTestGroup = false;
    },
    closeAddTestPackageView: (state) => {
      state.testPackages.isAddTestPackage = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  openAddUOMView,
  openAddInvestigationView,
  openAddTestGroupView,
  openAddTestPackagesView,
  closeAddUOMView,
  closeAddInvestigationView,
  closeAddTestGroupView,
  closeAddTestPackageView,
  clearState,
} = labSlice.actions;
export default labSlice.reducer;
