import { createSlice } from '@reduxjs/toolkit';
import { IReviewDialog } from '../interfaces/reviewDialog';

const initialState: IReviewDialog = {
  showDropdown: false,
  showNewReviewDialog: false,
  showNewReviewFilterDialog: false,
  reviewType: '',
  reviewHeading: '',
};

const newReviewSlice = createSlice({
  name: 'newReview',
  initialState,
  reducers: {
    toggleShowDropdown: (state) => {
      state.showDropdown = !state.showDropdown;
    },
    toggleNewReviewDialog: (state, { payload }) => {
      state.showNewReviewDialog = payload;
    },
    toggleNewReviewFilterDialog: (state, { payload }) => {
      state.showNewReviewFilterDialog = payload;
    },
    setReviewType: (state, { payload }) => {
      state.reviewType = payload;
    },
    setReviewHeading: (state, { payload }) => {
      state.reviewHeading = payload;
    },
  },
});

export const {
  toggleShowDropdown,
  toggleNewReviewDialog,
  toggleNewReviewFilterDialog,
  setReviewType,
  setReviewHeading,
} = newReviewSlice.actions;

export default newReviewSlice.reducer;
