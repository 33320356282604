import { createSlice } from '@reduxjs/toolkit';
export interface Branches {
  id: number;
  code: string;
  name: string;
}
export interface BranchOptions {
  branch_id: number;
  label: string;
  code: string;
  value: string;
}
const branches: Branches[] = [];
const defaultBranch = <BranchOptions>{};
const branchOptions: BranchOptions[] = [];
const initialState = {
  branches,
  defaultBranch,
  branchOptions,
};

const clinicsSlice = createSlice({
  name: 'Clinics',
  initialState,
  reducers: {
    allBranches: (state, { payload }) => {
      state.branches = payload;
    },
    setDefaultBranch: (state, { payload }) => {
      state.defaultBranch = payload;
    },
    setBranchOptions: (state, { payload }) => {
      state.branchOptions = payload;
    },
  },
});
export const { allBranches, setDefaultBranch, setBranchOptions } =
  clinicsSlice.actions;
export default clinicsSlice.reducer;
