import { createSlice } from '@reduxjs/toolkit';
import { MultiValue } from 'react-select';
export interface HealthCareServices {
  id: string;
  name: string;
  icon: string;
  color: string;
  selected: boolean;
  type: string;
}
export interface ServiceOptions {
  service_id: string;
  label: string;
  value: string;
}
const healthCareServices: HealthCareServices[] = [];
const servicesOptions: MultiValue<ServiceOptions> = [];
const defaultService = <ServiceOptions>{};

const initialState = {
  healthCareServices: healthCareServices,
  servicesOptions,
  defaultService,
};

const healthCareServiceSlice = createSlice({
  name: 'Health Care Services',
  initialState,
  reducers: {
    allServices: (state, { payload }) => {
      state.healthCareServices = payload;
    },
    toggleSelectedService: (state, { payload }) => {
      state.healthCareServices = state.healthCareServices.map((each) => {
        if (each.id === payload) {
          each.selected = !each.selected;
        }
        return each;
      });
    },
    setServicesOptions: (state, { payload }) => {
      state.servicesOptions = payload;
    },
    setDefaultService: (state, { payload }) => {
      state.defaultService = payload;
    },
  },
});
export const {
  allServices,
  setServicesOptions,
  setDefaultService,
  toggleSelectedService,
} = healthCareServiceSlice.actions;
export default healthCareServiceSlice.reducer;
