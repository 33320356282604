import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  vitalSigns: [],
  isAddVitalSign: false,
  isEditVitalSign: false,
  showForm: false,
};

const VitalSignSlice = createSlice({
  name: 'vital-signs',
  initialState,
  reducers: {
    updateVitalSigns: (state, { payload }) => {
      state.vitalSigns = payload;
    },
    openAddVitalSignForm: (state) => {
      state.isAddVitalSign = true;
      state.showForm = true;
    },
    openEditVitalSignForm: (state) => {
      state.isEditVitalSign = true;
      state.showForm = true;
    },
    closeVitalSignForm: (state) => {
      (state.isAddVitalSign = false), (state.isEditVitalSign = false);
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});
export const {
  updateVitalSigns,
  openAddVitalSignForm,
  openEditVitalSignForm,
  closeVitalSignForm,
  clearState,
} = VitalSignSlice.actions;
export default VitalSignSlice.reducer;
