import { createSlice } from '@reduxjs/toolkit';
import { ProviderOptions } from './CareProviderSlice';
export interface Rooms {
  branch_code: string;
  id: number;
  label: string;
  state: string;
  value: string;
}
export interface PreviousRoom {
  branch_code: string;
  id: number;
  label: string;
  state: string;
  code: string;
  room_type: string;
  value: string;
}
export interface RoomType {
  branch_code: string;
  id: number;
  name: string;
  room_type: string;
  state: string;
  code: string;
  sequence: number;
  create_date: string;
}

export interface RoomTicket {
  branch_code: string;
  id: number;
  name: string;
  room_id: number;
  stage_id: number;
  patient_name: string;
  patient_number: string;
  careprovider_name: string;
  clinic_time: string;
  checkin_time: string;
  room_time: string;
  status_time: string;
  labtest_no: string;
  prescription_no: string;
  encounter_no: string;
  stage: {
    name: string;
  };
  room: {
    room_type: string;
    name: string;
    id: number;
  };
  room_duration: string;
  appointment_code: string;
  write_username: string;
  create_username: string;
}
export interface Stages {
  id: number;
  label: string;
  value: string;
}
const rooms: Rooms[] = [];
const stages: Stages[] = [];
const checkInProviders: ProviderOptions[] = [];
const selectedRoom = {} as Rooms;
const previousRoom = {} as PreviousRoom;
const selectedStage = {} as Stages;
const roomType: RoomType[] = [];
const roomTicket: RoomTicket[] = [];
const ticketId: number | null = null;
const initialState = {
  rooms,
  stages,
  checkInProviders,
  selectedRoom,
  previousRoom,
  selectedStage,
  roomType,
  roomTicket,
  ticketId,
};

const patientFlowTicket = createSlice({
  name: 'patientFlowTicket',
  initialState,
  reducers: {
    availableRooms: (state, { payload }) => {
      state.rooms = payload;
    },
    availableStages: (state, { payload }) => {
      state.stages = payload;
    },
    availableProviders: (state, { payload }) => {
      state.checkInProviders = payload;
    },
    updateSelectedRoom: (state, { payload }) => {
      state.selectedRoom = payload;
    },
    updatePreviousRoom: (state, { payload }) => {
      state.previousRoom = payload;
    },
    updateSelectedStage: (state, { payload }) => {
      state.selectedStage = payload;
    },
    updateRoomType: (state, { payload }) => {
      state.roomType = payload;
    },
    addRoomTypeBySocket: (state, { payload }) => {
      state.roomType = [...state.roomType, payload];
    },
    updateRoomTypeBySocket: (state, { payload }) => {
      state.roomType = state.roomType.map((eachRoom) => {
        if (
          eachRoom.id === payload.id &&
          eachRoom.create_date === payload.create_date // Since the socket is not specified to a particular server environment, similar id is not enough for rooms. Create Date add to the uniqueness
        ) {
          eachRoom = payload;
        }
        return eachRoom;
      });
    },
    removeRoomTypeBySocket: (state, { payload }) => {
      state.roomType = state.roomType.filter((eachRoom) => {
        return eachRoom.id !== payload.id;
      });
    },
    updateRoomTicket: (state, { payload }) => {
      state.roomTicket = payload;
    },
    addRoomTicketBySocket: (state, { payload }) => {
      state.roomTicket = [payload, ...state.roomTicket];
    },
    updateRoomTicketBySocket: (state, { payload }) => {
      state.roomTicket = state.roomTicket.map((eachRoom) => {
        // appointment code is unique for each ticket
        if (eachRoom.appointment_code === payload.appointment_code) {
          eachRoom = payload;
        }
        return eachRoom;
      });
    },
    removeRoomTicketBySocket: (state, { payload }) => {
      state.roomTicket = state.roomTicket.filter((eachTicket) => {
        return eachTicket.id !== payload.id;
      });
    },

    updateTicketId: (state, { payload }) => {
      state.ticketId = payload;
    },
    updateRoomTicketByDragging: (state, { payload }) => {
      const ticket = state.roomTicket.find(
        (eachTicket) => eachTicket.id === payload.ticketId,
      );

      const previousRoom = state.roomType.find(
        (eachRoom) => eachRoom.id === payload.previousRoomId,
      );

      const room = state.roomType.find(
        (eachRoom) => eachRoom.id === payload.destinationRoomId,
      );

      const canBeOccupied = ['exam_room', 'dental', 'opthalmology', 'lab'];
      const canBeDirty = ['exam_room', 'dental', 'opthalmology'];

      if (canBeDirty.includes(previousRoom?.room_type as string)) {
        state.roomType = state.roomType.map((eachRoom) => {
          if (eachRoom.id === previousRoom?.id) {
            eachRoom.state = 'dirty';
          }
          return eachRoom;
        });
      }
      state.roomTicket = state.roomTicket.map((eachTicket) => {
        if (eachTicket.id === ticket?.id) {
          eachTicket.room_id = room?.id as number;
        }
        return eachTicket;
      });
      if (canBeOccupied.includes(room?.room_type as string)) {
        state.roomType = state.roomType.map((eachRoom) => {
          if (eachRoom.id === room?.id) {
            eachRoom.state = 'occupied';
          }
          return eachRoom;
        });
      }

      if (previousRoom?.room_type === 'lab') {
        state.roomType = state.roomType.map((eachRoom) => {
          if (eachRoom.id === previousRoom?.id) {
            eachRoom.state = 'clean';
          }
          return eachRoom;
        });
      }
    },
  },
});

export const {
  availableRooms,
  updateSelectedRoom,
  availableStages,
  updateSelectedStage,
  availableProviders,
  updateRoomType,
  updateRoomTypeBySocket,
  updateRoomTicket,
  addRoomTicketBySocket,
  updateRoomTicketBySocket,
  updatePreviousRoom,
  updateTicketId,
  removeRoomTicketBySocket,
  removeRoomTypeBySocket,
  addRoomTypeBySocket,
  updateRoomTicketByDragging,
} = patientFlowTicket.actions;
export default patientFlowTicket.reducer;
