import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plans: [],
  isAddPlan: false,
  isEditPlan: false,
  showForm: false,
};

const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    updatePlans: (state, { payload }) => {
      state.plans = payload;
    },
    openAddPlanForm: (state) => {
      state.isAddPlan = true;
      state.showForm = true;
    },
    openEditPlanForm: (state) => {
      state.isEditPlan = true;
      state.showForm = true;
    },
    closePlanForm: (state) => {
      state.isAddPlan = false;
      state.isEditPlan = false;
      state.showForm = false;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updatePlans,
  openAddPlanForm,
  openEditPlanForm,
  closePlanForm,
  clearState,
} = planSlice.actions;
export default planSlice.reducer;
